import PropTypes from 'prop-types';
import { toast, Bounce } from 'react-toastify';

const Toast = ({ message, type, toastId, isLoading = false }) =>
  toast(message, {
    type,
    position: 'top-right',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light',
    transition: Bounce,
    isLoading,
    toastId,
  });

Toast.dismiss = (toastId) => {
  toast.dismiss(toastId);
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  toastId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
};

export default Toast;
