import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import {
  Endpoints,
  GenericError,
  DistributorUserIDRequiredList,
  UserId,
  Verbiage,
} from './../../../constants';
import httpService from './../../../services/http-service';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import ModalBox from './../../../sharedcomponents/modal-box/ModalBox';
import './clone-user-entitlements.scss';
import { debounce } from '../../../helpers/debounce';

const CloneUserEntitlements = ({
  showCloneUserEntitlements,
  handleCloseCloneUserEntitlements,
  handleCloneUserEntitlementsSuccess,
  selectedToUsers,
  cloneEntitlementData,
  setCloneEntitlementData,
}) => {
  const [rowData, setRowData] = useState();
  const [currentlySelectedSourceUsers, setCurrentlySelectedSourceUsers] =
    useState([]);
  const [sourceUserChanged, setSourceUserChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userIdsChanged, setUserIdsChanged] = useState(false);
  const [initialExUsers, setInitialExUsers] = useState([]);
  const gridApi = useRef(null);
  const gridRef = useRef(null);
  const [isManageUseTab, setIsManageUseTab] = useState(false);
  const [userRowData, setUserRowData] = useState([]);
  const [userInitialRowData, setUserInitialRowData] = useState([]);
  const [errordetails, setErrordetails] = useState('');
  const sourceUsersGridStyle = { height: '45vh' };
  const sourceManageUserIdGridStyle = { height: '55vh' };

  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
    rowSelection: 'single',
    suppressRowClickSelection: false,
    suppressMultiRangeSelection: true,
    overlayNoRowsTemplate: Verbiage.noData,
  };

  const defaultColdef = {
    filter: false,
    sortable: false,
    enableRowGroup: false,
    enablePivot: false,
    enableValue: false,
    resizable: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
  };

  const UserIdDefaultColdef = {
    sortable: true,
    resizable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  const onManageUserGridReadyHandler = (params) => {
    gridRef.current = params;
  };
  const onInputsChanged = (el, prop, params) => {
    let index = userRowData.findIndex(
      (x) =>
        x.distributorId === params.data?.distributorId &&
        x.contactId === params.data?.contactId,
    );
    if (index >= 0) {
      userRowData[index][prop] = el.target?.value;
    }
    setUserRowData([...userRowData]);
  };
  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const onInputsChangedDebounce = (el, prop, params) => {
    debounce(onInputsChanged(el, prop, params), 1000);
  };
  const manageUserIdColumns = [
    {
      headerName: '',
      field: 'contactId',
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
    },
    {
      headerName: 'Email ID',
      headerTooltip: 'Email ID',
      field: 'emailId',
      tooltipField: 'emailId',
      initialFlex: 3,
    },
    {
      headerName: 'User Id',
      headerTooltip: 'User Id',
      field: 'userId',
      tooltipField: 'userId',
      initialFlex: 4,
      singleClickEdit: true,
      editable: true,
      cellRenderer: (params) => {
        return (
          <div className="aggrid-input-border">
            {params.data?.userId ? params.data.userId : ''}
          </div>
        );
      },
      menuTabs: ['generalMenuTab'],
      cellClass: 'aggrid-input-wrapper',
    },

    {
      headerName: 'Distributor Platform',
      headerTooltip: 'Distributor Platform',
      field: 'distributorPlatform',
      tooltipField: 'distributorPlatform',
      initialFlex: 3,
    },
    {
      headerName: 'Distributor',
      headerTooltip: 'Distributor',
      field: 'distributor',
      tooltipField: 'distributor',
      initialFlex: 3,
    },
    {
      headerName: 'User ID Format',
      headerTooltip: 'User ID Format',
      field: 'userIdFormat',
      tooltipField: 'userIdFormat',
      initialFlex: 3,
    },
  ];
  const onCloneUserEntitlementsClose = async () => {
    handleCloseCloneUserEntitlements();
  };

  const onSelectionChanged = () => {
    let selectedUsers = [];
    gridApi.current.api.getSelectedNodes().forEach((node) => {
      selectedUsers.push(node.data);
    });
    setCurrentlySelectedSourceUsers(selectedUsers);
  };

  useEffect(() => {
    if (currentlySelectedSourceUsers?.length > 0) {
      setSourceUserChanged(true);
    } else {
      setSourceUserChanged(false);
    }
  }, [currentlySelectedSourceUsers]);

  const onSourceUserGridReadyHandler = (params) => {
    gridApi.current = params;
    params.api.sizeColumnsToFit('contactName', false);
    params.api.setHeaderHeight(0);
    params.api.showLoadingOverlay();
  };

  const contactNameEmailGetter = (params) => {
    if (params.data)
      return params.data.contactName + ' (' + params.data.email + ')';

    return '';
  };

  const sourceUsersColumns = [
    {
      headerName: '',
      field: 'contactName',
      suppressHeaderMenuButton: true,
      sortable: false,
      valueGetter: contactNameEmailGetter,
      tooltipValueGetter: contactNameEmailGetter,
    },
  ];

  const getToUsers = async (dataFromAddUser) => {
    let selectedUserContractNumbers = [];
    let contractNumber = '';
    if (selectedToUsers?.length > 0) {
      selectedUserContractNumbers = [
        ...new Set(selectedToUsers.map((st) => st.contractNumber)),
      ];
      contractNumber = selectedUserContractNumbers
        .map((t) => (t ? '"' + t + '"' : ''))
        .join(',');
    } else {
      contractNumber = dataFromAddUser?.contractNumber?.includes('"')
        ? dataFromAddUser?.contractNumber
        : '"' + dataFromAddUser?.contractNumber + '"';
    }

    try {
      let queryParams = {
        PageSize: 50000,
        id: new Date().getTime(),
        Field: 'contractNumber,contactName,contactId,email',
        Filter: `contractNumber IN(${contractNumber}) AND entitled:"Yes"`,
      };

      let emailToExclude = selectedToUsers?.map((el) => el?.email);

      const sourceUsersResponse = await httpService.get(
        Endpoints.userApi,
        queryParams,
      );
      // Exclude clone to emails
      let filteredData = sourceUsersResponse?.data?.results?.filter(
        ({ email }) => !emailToExclude?.includes(email),
      );
      setRowData(filteredData);
    } catch (err) {
      gridApi?.current?.api?.showNoRowsOverlay();
      Notify({
        alert: true,
        type: 'error',
        title: GenericError.somethingWentWrong,
      });
    }
  };
  useEffect(() => {
    if (
      (selectedToUsers && selectedToUsers.length > 0) ||
      cloneEntitlementData
    ) {
      getToUsers(cloneEntitlementData);
    }
  }, [selectedToUsers, cloneEntitlementData]);

  const onFilterTextBoxChanged = () => {
    if (rowData) {
      gridApi.current.api.setQuickFilter(
        document.getElementById('search-filter-input').value,
      );
      if (gridApi.current.api.getDisplayedRowCount() <= 0) {
        gridApi?.current?.api?.showNoRowsOverlay();
      } else {
        gridApi?.current?.api.hideOverlay();
      }
    }
  };

  useEffect(() => {
    let initialRowData = !userInitialRowData ? [] : userInitialRowData;
    let modifiedRowData = !userRowData ? [] : userRowData;
    if (objectsEqual(modifiedRowData, initialRowData)) {
      setUserIdsChanged(false);
    } else {
      setUserIdsChanged(true);
    }
  }, [userRowData]);

  const getRequiredEntitlementMappings = async () => {
    try {
      if (currentlySelectedSourceUsers?.length > 0) {
        let queryParams = {
          id: new Date().getTime(),
          field: 'distributorId,distributor,distributorPlatform,userIdFormat',
          pageSize: 5000,
          Filter: `contractNumber:"${
            currentlySelectedSourceUsers[0].contractNumber
          }" AND contactid IN(${currentlySelectedSourceUsers
            .map((x) => '"' + x.contactId + '"')
            .join(',')}) AND distributor IN(${DistributorUserIDRequiredList.map(
            (d) => '"' + d + '"',
          ).join(',')}) AND status IN("Active","Pending Active")`,
        };
        const requiredMappings = await httpService.get(
          Endpoints.userentitlementsApi,
          queryParams,
        );
        return requiredMappings?.data?.results;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  };

  const getSelectedUserIds = async () => {
    try {
      if (selectedToUsers?.length > 0) {
        let queryParams = {
          id: new Date().getTime(),
          field:
            'distributorId,distributor,distributorPlatform,userId,contactId',
          pageSize: 5000,
          Filter: `contactid IN(${selectedToUsers
            .map((x) => '"' + x.contactId + '"')
            .join(',')}) AND distributor IN(${DistributorUserIDRequiredList.map(
            (d) => '"' + d + '"',
          ).join(',')})`,
        };
        const requiredMappings = await httpService.get(
          Endpoints.distributorUserApi,
          queryParams,
        );
        setInitialExUsers(requiredMappings?.data?.results);
        return requiredMappings?.data?.results;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  };

  const validateUserIdForEntitlement = async () => {
    try {
      if (
        currentlySelectedSourceUsers &&
        currentlySelectedSourceUsers.length > 0
      ) {
        setLoading(true);
        const sourceUsersResponse = await getRequiredEntitlementMappings();
        if (sourceUsersResponse) {
          if (sourceUsersResponse.length > 0) {
            const existingUserIds = await getSelectedUserIds();
            if (existingUserIds) {
              setIsManageUseTab(true);
              let gridData = [];
              selectedToUsers.map((user) => {
                let userRequiredEntitlements = sourceUsersResponse.map(
                  (resp) => {
                    let existingIdIndex = -1;
                    if (existingUserIds?.length > 0) {
                      existingIdIndex = existingUserIds.findIndex(
                        (uid) =>
                          uid.distributorId === resp.distributorId &&
                          uid.contactId === user.contactId,
                      );
                    }
                    return {
                      userId:
                        existingIdIndex == -1
                          ? ''
                          : existingUserIds[existingIdIndex].userId,
                      userIdFormat: resp.userIdFormat,
                      distributorId: resp.distributorId,
                      distributor: resp.distributor,
                      distributorPlatform: resp.distributorPlatform,
                      contactId: user.contactId,
                      emailId: user.email,
                    };
                  },
                );
                gridData = gridData.concat(userRequiredEntitlements);
              });
              let initialData = gridData?.map((data) => ({
                ...data,
              }));
              setUserInitialRowData(initialData);
              setUserRowData(gridData);
              gridRef?.current?.api?.hideOverlay();
            }
          } else {
            gridRef?.current?.api?.showNoRowsOverlay();
            cloneEntitlements();
          }
        }
      }
    } catch (error) {
      Notify({
        alert: true,
        type: 'error',
        title: GenericError.somethingWentWrong,
      });
    } finally {
      setLoading(false);
    }
  };

  const cloneEntitlements = () => {
    try {
      if (
        currentlySelectedSourceUsers &&
        currentlySelectedSourceUsers.length > 0
      ) {
        let sourceUsers = currentlySelectedSourceUsers
          .map((t) => t.contactId)
          .join(',');
        let destinationUsers =
          cloneEntitlementData?.contactId ??
          selectedToUsers
            .map((t) => t.contactId)
            .filter(
              (value, index, current_value) =>
                current_value.indexOf(value) === index,
            )
            .join(',');
        let params = {
          fromUsers: sourceUsers,
          toUsers: destinationUsers,
        };
        setLoading(true);
        httpService
          .post(Endpoints.userEntitlementsCloneApi, null, params)
          .then(() => {
            handleCloneUserEntitlementsSuccess();
            onCloneUserEntitlementsClose();
            Notify({
              alert: true,
              type: 'success',
              title: 'User entitlements cloned successfully',
            });
            setLoading(false);
          })
          .catch(() => {
            Notify({
              alert: true,
              type: 'error',
              title: GenericError.somethingWentWrong,
            });
          })
          .finally(() => setLoading(false));
      }
    } catch (err) {}
  };
  const getChangedDistributorUsers = () => {
    let updatedDistributorUsers = [];
    userRowData.map((du) => {
      let existingDistributorUsers = initialExUsers.filter(
        (x) => x.distributorId === du.distributorId,
      );
      if (existingDistributorUsers?.length > 0) {
        if (du.userId !== existingDistributorUsers[0].userId)
          updatedDistributorUsers.push(du);
      } else {
        if (du.userId) updatedDistributorUsers.push(du);
      }
    });
    return updatedDistributorUsers;
  };

  const saveDistributorUserIds = async () => {
    try {
      if (userRowData.every((u) => u.userId?.trim())) {
        const changedUserIds = getChangedDistributorUsers();
        setErrordetails('');
        setLoading(true);
        if (changedUserIds?.length > 0) {
          let postUserDistributor = {
            distributorUsers: changedUserIds.map((ur) => {
              return {
                userId: ur.userId,
                contactId: ur.contactId,
                distributorId: ur.distributorId,
              };
            }),
            userId: UserId,
          };
          let postDistributorUserResponse = await httpService.post(
            Endpoints.distributorUserApi,
            postUserDistributor,
          );
          if (postDistributorUserResponse) cloneEntitlements();
        } else {
          cloneEntitlements();
        }
      } else {
        setErrordetails(
          'Please enter user id for ' + DistributorUserIDRequiredList.join(','),
        );
      }
    } catch (err) {
      setLoading(false);
      Notify({
        alert: true,
        type: 'error',
        title: GenericError.somethingWentWrong,
      });
    }
  };

  return (
    <>
      <ModalBox
        backdrop="static"
        size="lg"
        show={showCloneUserEntitlements}
        handleClose={onCloneUserEntitlementsClose}
        showLoader={loading}
        modalTitle={
          isManageUseTab ? (
            <>Clone - Manage User IDs</>
          ) : (
            <>
              Clone Users
              <span className="clone-user-entitlements-subtitle">
                (Select user to clone from)
              </span>
            </>
          )
        }
      >
        <div className="container">
          {isManageUseTab ? (
            <>
              <div className="row">
                <div className="col no-scroll">
                  <AgGridComponent
                    config={defaultConfig}
                    gridStyle={sourceManageUserIdGridStyle}
                    defaultColumnDef={UserIdDefaultColdef}
                    columns={manageUserIdColumns}
                    data={userRowData}
                    onGridReady={onManageUserGridReadyHandler}
                  />
                </div>
              </div>

              <Row className="modal-row">
                <div className="col-8 text-danger mt-2">{errordetails}</div>
                <div className="col-4">
                  <Form>
                    <Form.Group className="entitlements-clone-btn-body">
                      <button
                        type="button"
                        disabled={!userIdsChanged}
                        className="btn btn-dark float-end btn-opacity"
                        onClick={saveDistributorUserIds}
                      >
                        Save
                      </button>
                    </Form.Group>
                  </Form>
                </div>
              </Row>
            </>
          ) : (
            <div>
              <div className="row mt-2">
                <div className="col">
                  <input
                    type="text"
                    id="search-filter-input"
                    className="form-control search-box"
                    placeholder="Search by name, email address"
                    onInput={onFilterTextBoxChanged}
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col no-scroll">
                  <AgGridComponent
                    config={defaultConfig}
                    gridStyle={sourceUsersGridStyle}
                    defaultColumnDef={defaultColdef}
                    data={rowData}
                    suppressRowClickSelection={false}
                    columns={sourceUsersColumns}
                    onSelectionChanged={onSelectionChanged}
                    onGridReady={onSourceUserGridReadyHandler}
                  />
                </div>
              </div>

              <Row className="modal-row">
                <Form>
                  <Form.Group className="col-12 entitlements-clone-btn-body">
                    <button
                      type="button"
                      disabled={!sourceUserChanged}
                      onClick={validateUserIdForEntitlement}
                      className="btn btn-dark float-end btn-opacity"
                    >
                      Save Entitlements
                    </button>
                  </Form.Group>
                </Form>
              </Row>
            </div>
          )}
        </div>
      </ModalBox>
    </>
  );
};

export default memo(CloneUserEntitlements);
