import React from 'react';

const InvalidRegistrationLink = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <h4>404 - Invalid Registration Link</h4>
    </div>
  );
};

export default InvalidRegistrationLink;
