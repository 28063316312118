import React from 'react';

import './end-user-registration.scss';

const EndUserRegistrationSuccess = () => {
  return (
    <div className="centered-content">
      <p>
        <h3>
          Registration Successful! Please check your email for login details.
        </h3>
        <h6>There are no further actions pending. Please close this page.</h6>
      </p>
    </div>
  );
};

export default EndUserRegistrationSuccess;
