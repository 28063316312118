import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { loaderStyle } from './../../constants';
import './locations.scss';

const AddressVerificationPopup = ({
  loading,
  handleClose,
  handleSubmit,
  responseData,
  submittedAddress,
}) => {
  const [isChecked, setIsChecked] = useState('-1');

  const handleCheckedChange = (event) => {
    setIsChecked(event.target.value);
  };
  const getFullAddress = (addressObject) => {
    return `${addressObject.address_1 ? addressObject.address_1 + ', ' : ''}${
      addressObject.address_2 ? addressObject.address_2 + ', ' : ''
    }${addressObject.city}, ${addressObject.state} ${
      addressObject.postalCode
    }, ${addressObject.country}`;
  };
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        size="md"
        centered
        backdrop="static"
      >
        {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}

        <Modal.Header>
          <div className="header-title-txt">Review the recommended changes</div>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 w-100">
            <div className="address-wrapper">
              {/* What you entered block starts here */}
              <div className="address-suggestion">What you entered</div>
              <div className="address-block border-bottom-0">
                <span className="ps-1 pe-1">
                  <input
                    type="radio"
                    name="address"
                    value={-1}
                    id="addressEntered"
                    className="form-check-input"
                    checked={isChecked === '-1'}
                    onChange={handleCheckedChange}
                  />
                </span>
                <label htmlFor="addressEntered">{submittedAddress}</label>
              </div>

              {/* Recommended block starts here */}
              <div className="address-suggestion mt-3">Recommended</div>
              {/* Address 1 */}
              <div className="address-height-fix">
                {responseData?.length > 0 &&
                  responseData.map((response, index) => {
                    return (
                      <div className=" address-block" key={index}>
                        <span className="ps-1 pe-1">
                          <input
                            type="radio"
                            name="address"
                            value={index}
                            id={'addressRecommended' + index}
                            className="form-check-input"
                            checked={isChecked === '' + index}
                            onChange={handleCheckedChange}
                          />
                        </span>
                        <label htmlFor={'addressRecommended' + index}>
                          {getFullAddress(response)}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <Row>
            <Col>
              <button
                className="btn btn-dark btn-opacity float-end"
                type="button"
                onClick={() => handleSubmit(isChecked)}
              >
                Confirm
              </button>
              <button
                className="btn btn-outline-dark float-end me-2"
                type="button"
                onClick={handleClose}
              >
                Back
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddressVerificationPopup;
