export const setTitle = (routeName) => {
  let title = 'CI Subscription Manager';

  const getCaseMatch = (caseValue) => {
    if (routeName.includes(caseValue)) return routeName;
    else return caseValue;
  };
  switch (routeName) {
    case '/user-location':
    case '/export-users':
      title = 'User & Location | CI Subscription Manager';
      break;
    case '/user-group':
      title = 'My Groups | CI Subscription Manager';
      break;
    case '/entitlements':
    case '/entitlements-serverside':
    case '/add-entitlements':
    case '/product-entitlements':
    case '/export-entitlements':
      title = 'Entitlements | CI Subscription Manager';
      break;
    case getCaseMatch('/packages'):
      title = 'Package & Delivery | CI Subscription Manager';
      break;
    case '/user-dashboard':
      title = 'User Profile | CI Subscription Manager';
      break;
    case '/change-history':
      title = 'Change History | CI Subscription Manager';
      break;
    case '/manage-email-domains':
      title = 'Manage Email Domains | CI Subscription Manager';
      break;
    case '/admin-dashboard':
      title = 'Home | CI Subscription Manager';
      break;
    case '/my-subscription':
      title = 'My Subscription | CI Subscription Manager';
      break;
    case '/user-info':
      title = 'User Profile | CI Subscription Manager';
      break;
    case '/onboard-contracts':
      title = 'Onboard Contracts | CI Subscription Manager';
      break;
    default:
      title = 'CI Subscription Manager';
      break;
  }
  document.title = title;
};
