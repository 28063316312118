import React, { useState } from 'react';
import httpService from '../../services/http-service';
import { Col, Modal, Row, Form } from 'react-bootstrap';
import { GenericError, Endpoints } from './../../constants';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextAreaInput from '../../sharedcomponents/TextAreaInput/TextAreaInput';

const ContractDescriptionModal = ({
  handleClose,
  onSave,
  setLoading,
  data,
}) => {
  const [initialValue, setInitialValue] = useState({
    description: data.contractDescription ? data.contractDescription : '',
  });

  const validate = Yup.object({
    description: Yup.string().trim().max(50, 'Must be 50 characters or less'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validate,
    onSubmit: (data) => {
      let description = data.description;
      saveContractDescription(description);
    },
  });

  const saveContractDescription = (description) => {
    try {
      setLoading(true);
      const postData = {
        contractNumber: data.contractNumber,
        contractDescription: description,
      };

      httpService
        .post(Endpoints.contractApi, postData)
        .then((resp) => {
          if (resp?.data === 0) {
            Notify({
              alert: true,
              type: 'success',
              title: 'Your contract description has been saved.',
            });
            onSave(description);
          }
        })
        .catch((err) => {
          let errorMsg = GenericError.somethingWentWrong;
          Notify({
            alert: true,
            type: 'error',
            title: errorMsg,
          });
          setLoading(false);
          handleClose();
        });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        size="md"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <div className="header-title-txt">
            Contract Description ({data.contractNumber})
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className="row mt-2" onSubmit={formik.handleSubmit}>
            <div className="mb-3 w-100">
              <TextAreaInput
                formLabel="Please specify contract description"
                placeholder="Description"
                id="description"
                {...formik.getFieldProps('description')}
                formik={formik}
                className="contract-description-field"
              />
              <Row>
                <div>Max length of 50 characters</div>
              </Row>
              <Row className="mb-n4">
                <Col>
                  <Form.Group className="col-12 user-save-btn-body">
                    <button
                      className="btn btn-outline-dark float-end me-2"
                      type="button"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="Submit"
                      className="btn btn-dark float-end btn-opacity"
                    >
                      Save
                    </button>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContractDescriptionModal;
