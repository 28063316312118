import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  Active,
  Cancelled,
  Endpoints,
  PendingActive,
  PendingApproval,
  PendingInactive,
  ProductDeliveryPlatforms,
  Rejected,
  Verbiage,
  loaderStyle,
} from '../../../constants';
import { debounce } from '../../../helpers/debounce';
import httpService from '../../../services/http-service';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import { ReactComponent as ExportIcon } from './../../../assets/icons/common/export.svg';
import Loader from './../../../components/Loader';
import ProductsDropdown from './../../Entitlements/ProductsDropdown';

const MySubscriptionEmailPreferenceDetails = ({
  userEntitlements,
  contracts,
  userDetails,
  loadingProductTab,
  setLoadingProductTab,
  saveEntitlements,
  isRowSelectable,
}) => {
  const gridApi = useRef(null);
  const [rowData, setRowData] = useState();
  const [productDataset, setProductDataset] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableMappings, setAvailableMappings] = useState([]);
  const [initialEntitlements, setInitialEntitlements] = useState([]);
  const [deselectRowsData, setDeselectRowsData] = useState([]);
  const [isEntitlementsChanged, setIsEntitlementsChanged] = useState(false);
  const [isFirstDataSelection, setIsFirstDataSelection] = useState(true);
  const [isRowDataLoaded, setIsRowDataLoaded] = useState(false);

  const isFirstColumn = (params) => {
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params?.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };
  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    suppressDragLeaveHidesColumns: true,
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: true,
    overlayNoRowsTemplate: 'This user has no entitlements',
  };

  const defaultColdef = {
    sortable: true,
    resizable: true,
    headerCheckboxSelection: isFirstColumn,
    checkboxSelection: isFirstColumn,
    headerCheckboxSelectionFilteredOnly: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    // cellRenderer: (params) => {
    //   let displayedColumns = params?.api?.getAllDisplayedColumns();
    //   if (displayedColumns[0] === params?.column) {
    //     const checkboxMainDiv = params.eGridCell.querySelector(
    //       '.ag-selection-checkbox',
    //     );
    //     const checkbox = params.eGridCell.querySelector(
    //       'div[ref="eCheckbox"] input',
    //     );
    //     const checkboxWrapper = params.eGridCell.querySelector(
    //       'div[ref="eWrapper"]',
    //     );
    //     if (!isRowSelectable(params) && checkboxMainDiv) {
    //       checkboxMainDiv.classList.remove('ag-hidden');
    //       checkboxWrapper.classList.add('checkbox-disable');
    //       checkbox.disabled = true;
    //       checkbox.setAttribute(
    //         'title',
    //         Verbiage.reachOutToContractAdminToUpdateEntitlements,
    //       );
    //     }
    //   }

    //   return params.value ?? null;
    // },
  };

  const getColumnClass = (params) => {
    if (userDetails?.results[0]?.groupId) return '';
    if (
      (params?.node?.data?.status === Cancelled ||
        params?.node?.data?.status === PendingInactive ||
        params?.node?.data?.status === Rejected) &&
      !isRowDataLoaded
    ) {
      return 'unselected-entitlement-row';
    }
    if (
      (!params?.node?.data?.status ||
        params?.node?.data?.status === Cancelled ||
        params?.node?.data?.status === PendingInactive ||
        params?.node?.data?.status === Rejected) &&
      params?.node?.selected &&
      isRowDataLoaded
    ) {
      return 'new-selected-entitlement-row';
    }
    if (
      params?.node?.data?.status &&
      !params?.node?.selected &&
      isRowDataLoaded
    ) {
      return 'unselected-entitlement-row';
    } else {
      return '';
    }
  };

  const subscriptionColumns = [
    {
      headerName: '',
      field: 'id',
      resizable: true,
      filter: 'agTextColumnFilter',
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
    },
    {
      headerName: 'Package',
      headerTooltip: 'Package',
      field: 'packageName',
      tooltipField: 'packageName',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Product',
      headerTooltip: 'Product',
      field: 'productName',
      tooltipField: 'productName',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Status',
      headerTooltip: 'Status',
      field: 'status',
      tooltipField: 'status',
      initialFlex: 2,
      cellClass: getColumnClass,
    },
  ];

  const setSelectionInGrid = () => {
    if (deselectRowsData) {
      let nodesToDeselect = [];
      gridApi.current?.api.showLoadingOverlay();
      gridApi.current.api.selectAll();

      gridApi.current.api.forEachNode((node) => {
        // Deselect Cancelled, Rejected and Pending Inactive entitlements
        if ([PendingInactive, Cancelled, Rejected].includes(node.data.status))
          nodesToDeselect.push(node);
      });

      if (isRowDataLoaded && deselectRowsData?.length > 0) {
        gridApi?.current?.api?.forEachNode((node) => {
          if (
            deselectRowsData.some(
              (nd) => nd.productComponentId === node.data.productComponentId,
            )
          )
            nodesToDeselect.push(node);
        });
      }
      gridApi.current.api.setNodesSelected({
        nodes: nodesToDeselect,
        newValue: false,
      });
    }
    setDeselectRowsData([]);
    setIsRowDataLoaded(true);
    gridApi.current?.api.refreshCells();
    validateEntitlementChange();
    gridApi.current?.api.hideOverlay();
  };

  let inProgressFlag = false;
  const onRowSelected = async (params) => {
    const { api, node } = params;
    const isSelected = node.selected;

    if (!inProgressFlag) {
      inProgressFlag = true;

      if (isSelected) {
        let deSelectedRowIndex = deselectRowsData?.findIndex(
          (el) => el.productComponentId === node.productComponentId,
        );
        let newDeSelectedRow = deselectRowsData.splice(deSelectedRowIndex, 1);
        setDeselectRowsData(newDeSelectedRow);
      } else {
        let newDeSelectedRow = [...deselectRowsData, node.data];
        setDeselectRowsData(newDeSelectedRow);
      }

      gridApi.current?.api.refreshCells();

      setTimeout(() => {
        inProgressFlag = false;
      }, 200);
    }
  };

  const onFilterTextBoxChanged = useCallback(({ target }) => {
    let searchText = target.value;
    let moreThan2 = searchText && searchText.length > 2;
    // Apply custom search filter
    if (!searchText || moreThan2) {
      gridApi?.current?.api?.setQuickFilter(searchText);
    } else {
      gridApi?.current?.api?.setQuickFilter('');
    }

    let isRowExist = gridApi?.current?.api?.getRenderedNodes()?.length > 0;

    // Check if any row is rendered if not then show overlay
    if (moreThan2 && !isRowExist) {
      gridApi?.current?.api?.showNoRowsOverlay();
    } else {
      gridApi?.current?.api?.hideOverlay();
    }
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const onGridReadyHandler = (params) => {
    gridApi.current = params;
    params.api.showLoadingOverlay();
  };

  // useeffects starts
  useEffect(() => {
    let isMounted = true;
    if (isMounted && userEntitlements) {
      let mySubscriptions = userEntitlements.filter((ue) =>
        ProductDeliveryPlatforms.includes(ue.distributor),
      );
      setInitialEntitlements(mySubscriptions);
      setRowData(mySubscriptions);
      setIsRowDataLoaded(true);

      if (userEntitlements && userEntitlements?.length == 0) {
        gridApi.current?.api?.showNoRowsOverlay();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [userEntitlements]);

  useEffect(() => {
    // Controller to abort previous API calls if any ongoing flight
    const controller = new AbortController();
    const signal = controller.signal;
    getContractProducts(signal);
    return () => {
      controller?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedProducts.length >= 0 && availableMappings.length > 0) {
        let matchedMappings = [];
        selectedProducts?.map((product) => {
          let matchedRows = availableMappings.filter(
            (map) =>
              map.productId === product &&
              ProductDeliveryPlatforms.includes(map.distributor),
          );
          if (matchedRows?.length > 0) {
            matchedMappings = matchedMappings.concat(matchedRows);
          }
        });
        if (matchedMappings?.length > 0) {
          // filter out duplicate rows
          initialEntitlements.forEach((row) => {
            let nodeIndex = matchedMappings.findIndex(
              (nid) => nid.productComponentId === row.productComponentId,
            );
            if (nodeIndex !== -1) {
              matchedMappings.splice(nodeIndex, 1);
            }
          });
        }

        if (rowData) {
          gridApi.current?.api.showLoadingOverlay();
          let updatedData = matchedMappings.concat(initialEntitlements);
          setRowData(updatedData);
        }
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts, availableMappings]);

  useEffect(() => {
    let isMounted = true;
    const reduceCallback = setTimeout(() => {
      if (rowData && isMounted) {
        if (
          rowData.length > 0 &&
          isRowDataLoaded
          // && !isFirstDataSelection
        ) {
          setSelectionInGrid();
        } else {
          if (rowData.length === 0 && initialEntitlements?.length === 0) {
            setIsEntitlementsChanged(false);
          }
        }
      }
    }, 100);
    return () => {
      clearTimeout(reduceCallback);
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);
  // useeffects ends

  const getDate = () => {
    let currentDate = new Date();
    return (
      currentDate.getDate() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getFullYear()
    );
  };
  const exportEmailPreferenceCSV = () => {
    gridApi.current?.api?.exportDataAsCsv({
      fileName: 'EmailPreferenceSubscriptions_' + getDate(),
    });
  };
  const callBackProducts = (params) => {
    let dsts = [];
    params?.forEach((dst) => {
      dsts.push(dst?.productId);
    });
    setSelectedProducts(dsts);
  };
  const productCompare = (current, next) => {
    if (current.productName < next.productName) {
      return -1;
    }
    if (current.productName > next.productName) {
      return 1;
    }
    return 0;
  };

  const getContractProducts = (signal) => {
    if (contracts?.length > 0) {
      let productDistributors = ProductDeliveryPlatforms?.map(
        (t) => '"' + t + '"',
      ).join(',');

      let queryParams = {
        pageSize: 5000,
        id: new Date().getTime(),
        filter: `contractNumber in (${contracts
          .filter((x) => x.checked)
          .map((t) => (t.id ? '"' + t.id + '"' : ''))
          .join(',')}) ${
          productDistributors
            ? 'AND distributor in (' + productDistributors + ')'
            : ''
        }`,
        Field:
          'productId,productName,packageName,productComponentId,distributorId,distributor,distributorPlatform,deliveryType,contractNumber',
      };
      httpService
        .get(Endpoints.contractProductsApi, queryParams, { signal })
        .then((res) => {
          if (res) {
            let productData = [];
            // productData.metadata = res?.data?.metadata;
            setAvailableMappings(res?.data?.results);
            const allProducts = res?.data?.results?.map((prd) => {
              return { productId: prd.productId, productName: prd.productName };
            });
            let uniqueProducts = allProducts.filter((value, index, self) => {
              return (
                self.findIndex((x) => x.productId === value.productId) === index
              );
            });
            uniqueProducts?.sort(productCompare);
            productData.results = uniqueProducts;
            setProductDataset(productData);
          }
        })
        .catch((err) => {
          if (err.code !== 'ERR_CANCELED') setProductDataset({});
        });
    } else if (contracts) {
      setProductDataset({});
    }
  };

  const updateEntitlements = () => {
    // Save entitlements
    try {
      if (userDetails && !userDetails?.results[0]?.groupId) {
        setLoadingProductTab(true);
        let entitlementChanges = getEntitlementChanges();

        saveEntitlements(entitlementChanges, true, () => {
          setDeselectRowsData([]);
          setProductDataset({ ...productDataset });
        });
      }
    } catch (err) {
      setLoadingProductTab(false);
    }
  };

  const getEntitlementChanges = () => {
    let contactId = userDetails?.results[0].contactId;
    let entitlementChanges = [];

    gridApi?.current?.api?.forEachNode((node) => {
      // remove entitlements that have a status and aren't selected
      if (
        node.data.status &&
        [Active, PendingActive, PendingApproval].includes(node.data.status) &&
        !node.selected
      ) {
        entitlementChanges.push({
          contactId: contactId,
          productComponentId: node.data.productComponentId,
          status: PendingInactive,
          contractNumber: node.data.contractNumber,
          userEntitlementId: node.data.userEntitlementId,
        });
      }
      // add entitlements that don't have a status and are selected
      if (
        (!node.data.status ||
          [PendingInactive, Cancelled, Rejected].includes(node.data.status)) &&
        node.selected
      ) {
        entitlementChanges.push({
          contactId: contactId,
          productComponentId: node.data.productComponentId,
          status: PendingActive,
          contractNumber: node.data.contractNumber,
        });
      }
    });

    return entitlementChanges;
  };

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const validateEntitlementChange = () => {
    let currentlySelectedEntitlements =
      gridApi?.current?.api?.getSelectedRows();
    let initialEntitlementsToValidateChanges = initialEntitlements?.filter(
      (x) =>
        x.status != Cancelled &&
        x.status != PendingInactive &&
        x.status != Rejected,
    );
    if (currentlySelectedEntitlements && initialEntitlementsToValidateChanges) {
      if (
        objectsEqual(
          currentlySelectedEntitlements,
          initialEntitlementsToValidateChanges,
        )
      ) {
        setIsEntitlementsChanged(false);
      } else {
        setIsEntitlementsChanged(true);
      }
    }
  };
  const onSelectionChanged = () => {
    validateEntitlementChange();
  };

  const productLoaderStyle = {
    ...loaderStyle,
    height: '-webkit-fill-available',
    marginTop: '-8px',
    zIndex: '1055',
  };

  return (
    <>
      {loadingProductTab && (
        <Loader type="scaleLoader" cssClass={productLoaderStyle} />
      )}
      <Container fluid>
        <div className="row">
          <div className="col-6 mt-2">
            <span className="email-preference-note-label">
              {userDetails?.results[0]?.groupId
                ? Verbiage.reachOutToContractAdminToUpdateEntitlements
                : 'Please select CI Market Reports & Insights for which you want to receive Email'}
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <input
              type="text"
              id="email-preference-search-filter-input"
              className="form-control search-box"
              placeholder="Search by packages, products or status"
              onInput={onFilterTextBoxChangedDebounce}
            ></input>
          </div>
          <div className="col-6 d-flex align-items-center">
            {userDetails && !userDetails?.results[0]?.groupId && (
              <div className="col-11 flex-grow-1">
                <ProductsDropdown
                  DataSet={productDataset}
                  callBackProducts={callBackProducts}
                />
              </div>
            )}
            <div className="col action-item-bar">
              <span className="user-grid-icons icon-divider">
                <ExportIcon
                  className={
                    rowData?.length > 0 ? 'icon-active' : 'cursor-default'
                  }
                  alt="Email Preference Export"
                  width="18"
                  title="Export email preferences"
                  onClick={exportEmailPreferenceCSV}
                ></ExportIcon>
              </span>
            </div>
          </div>
        </div>
        <div className="ag-grid-table end-user-email-preference mbx-12">
          <AgGridComponent
            config={defaultConfig}
            defaultColumnDef={defaultColdef}
            data={rowData}
            columns={subscriptionColumns}
            onGridReady={onGridReadyHandler}
            onSelectionChanged={onSelectionChanged}
            onRowSelected={onRowSelected}
            isRowSelectable={isRowSelectable}
          />
        </div>
        <div className="row">
          <div className="col-9 mt-1">
            <span className="email-preference-note-label">
              <span className="notes-asterisk-label">*</span>
              Please note that you can self-manage and customize Email alerts on
              Platts Connect
            </span>
          </div>
          <div className="col-3 mb-2">
            <button
              className="btn btn-dark float-end btn-opacity"
              disabled={
                !isEntitlementsChanged || userDetails?.results[0]?.groupId
              }
              onClick={updateEntitlements}
            >
              SAVE ENTITLEMENTS
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default MySubscriptionEmailPreferenceDetails;
