import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import store from './../../redux/store';
import { ReduxAction, setUserId } from './../../constants';
import { getDbConfigData } from './../../helpers/component-helper-methods';
import { setMaxExportSizeLimit } from './../../constants';

export const SecureRoute = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  useEffect(() => {
    let isMounted = true;
    // Call async function to retrieve user info
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo;
    if (storeUserInfo && storeUserInfo.userInfo) {
      setUserInfo(storeUserInfo.userInfo);
    } else {
      oktaAuth.token
        .getUserInfo()
        .then(async (userInfo) => {
          if (isMounted) {
            userInfo.email = userInfo.email?.toLowerCase();
            userInfo.UserID = userInfo.UserID?.toLowerCase();
            setUserInfo(userInfo);
            setLoading(false);
            dispatch({ type: ReduxAction.userInfoSet, payload: userInfo });
            setUserId(userInfo.UserID);
            let dbConfig = await getDbConfigData(dispatch);
            setMaxExportSizeLimit(dbConfig?.exportLimit ?? 0);
          }
        })
        .catch((error) => {
          if (isMounted) {
            setUserInfo(null);
            setLoading(false);
          }
        });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !authState || !authState?.isAuthenticated) {
    return <div></div>;
  } else if (userInfo) {
    if (
      !userInfo.SPGGroups?.includes('SFS_CCA') &&
      !userInfo.SPGGroups?.includes('GRP_SFS_CSA') &&
      !userInfo.SPGGroups?.includes('SFS_ENDUSER')
    ) {
      localStorage.clear();
      oktaAuth.signOut();
      return <div></div>;
    } else {
      localStorage.setItem('userID', userInfo.UserID);
      localStorage.setItem('token', authState.accessToken.accessToken);
      // get and set time for token renewal/auto-logout
      const loginTime = new Date().getTime();
      localStorage.setItem('lastLoginTime', loginTime);
      localStorage.setItem('initialLoginTime', loginTime);
      localStorage.setItem('refreshToken', authState.refreshToken.refreshToken);
      localStorage.setItem('clientId', authState.idToken.clientId);
      localStorage.setItem('expiresAt', authState.idToken.expiresAt);

      // if the user refreshes and already has a token, redirect them to the last page they were on
      let path = localStorage.getItem('currentRoute');
      if (localStorage.getItem('token') && path !== null && path !== '/') {
        return <Navigate to={path} replace={true} />;
      } else {
        // default page after being authenticated
        if (userInfo.SPGGroups.includes('GRP_SFS_CSA')) {
          return <Navigate to={'/admin-dashboard'} replace={true} />;
        } else {
          return <Navigate to={'/packages'} replace={true} />;
        }
      }
    }
  }
};
