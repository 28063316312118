import React, { memo, useEffect, useRef, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import {
  Endpoints,
  GenericError,
  maxPageSize,
  Verbiage,
} from './../../../constants';
import httpService from './../../../services/http-service';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import ModalBox from './../../../sharedcomponents/modal-box/ModalBox';
import './clone-user-group-entitlements.scss';

const CloneUserGroupEntitlements = ({
  showCloneUserGroupEntitlements,
  handleCloseCloneUserGroupEntitlements,
  selectedToUserGroup,
  handleSaveClone,
}) => {
  const [rowData, setRowData] = useState();
  const [
    currentlySelectedSourceUserGroups,
    setCurrentlySelectedSourceUserGroups,
  ] = useState([]);
  const [sourceUserGroupChanged, setSourceUserGroupChanged] = useState(false);
  const [cloneLoading, setCloneLoading] = useState(false);
  const gridApi = useRef(null);
  const sourceUserGroupsGridStyle = { height: '45vh' };

  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
    rowSelection: 'single',
    suppressRowClickSelection: false,
    suppressMultiRangeSelection: true,
    overlayNoRowsTemplate: Verbiage.noData,
  };

  const defaultColdef = {
    filter: false,
    sortable: false,
    enableRowGroup: false,
    enablePivot: false,
    enableValue: false,
    resizable: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
  };

  const onCloneUserGroupEntitlementsClose = async () => {
    handleCloseCloneUserGroupEntitlements();
  };

  const onSelectionChanged = () => {
    let selectedUserGroups = [];
    gridApi.current.api.getSelectedNodes().forEach((node) => {
      selectedUserGroups.push(node.data);
    });
    setCurrentlySelectedSourceUserGroups(selectedUserGroups);
  };

  useEffect(() => {
    if (currentlySelectedSourceUserGroups?.length > 0) {
      setSourceUserGroupChanged(true);
    } else {
      setSourceUserGroupChanged(false);
    }
  }, [currentlySelectedSourceUserGroups]);

  const onSourceUserGroupGridReadyHandler = (params) => {
    gridApi.current = params;
    params.api.sizeColumnsToFit('groupName', false);
    params.api.setHeaderHeight(0);
    params.api.showLoadingOverlay();
  };

  const sourceUserGroupsColumns = [
    {
      headerName: '',
      headerTooltip: 'groupName',
      field: 'groupName',
      suppressHeaderMenuButton: true,
      sortable: false,
    },
  ];

  const getToUserGroups = async () => {
    let contractNumber = '';
    contractNumber = selectedToUserGroup?.contractNumber?.includes('"')
      ? selectedToUserGroup?.contractNumber
      : '"' + selectedToUserGroup?.contractNumber + '"';

    try {
      let queryParams = {
        PageSize: 50000,
        id: new Date().getTime(),
        Field: 'groupId,groupName',
        Filter: `contractNumber IN(${contractNumber})`,
      };

      const sourceUserGroupsResponse = await httpService.get(
        Endpoints.userGroupApi,
        queryParams,
      );
      // Exclude clone to emails
      let filteredData = sourceUserGroupsResponse?.data?.results?.filter(
        ({ groupId }) => selectedToUserGroup?.groupId !== groupId,
      );
      setRowData(filteredData);
    } catch (err) {
      gridApi?.current?.api?.showNoRowsOverlay();
      Notify({
        alert: true,
        type: 'error',
        title: GenericError.somethingWentWrong,
      });
    }
  };
  useEffect(() => {
    if (selectedToUserGroup) {
      getToUserGroups();
    }
  }, [selectedToUserGroup]);

  const onFilterTextBoxChanged = () => {
    if (rowData) {
      gridApi.current.api.setQuickFilter(
        document.getElementById('search-filter-input').value,
      );
      if (gridApi.current.api.getDisplayedRowCount() <= 0) {
        gridApi?.current?.api?.showNoRowsOverlay();
      } else {
        gridApi?.current?.api.hideOverlay();
      }
    }
  };

  // get all user entitlement mappings
  const getEntitlementMappings = async () => {
    let entitlementAPISuccess = true;
    try {
      if (currentlySelectedSourceUserGroups[0]?.groupId) {
        let queryParams = {
          pageSize: maxPageSize,
          Filter: `contractNumber in("${selectedToUserGroup?.contractNumber}") AND groupId:"${currentlySelectedSourceUserGroups[0].groupId}"`,
          Sort: 'modifiedDate:desc',
        };

        const entitlementResponse = await httpService.get(
          Endpoints.userGroupEntitlementsApi,
          queryParams,
        );
        return entitlementResponse.data?.results.map((c, index) => {
          c.id = index;
          c.isDeleted = 0;
          return c;
        });
      }
    } catch {
      return [];
    }
  };

  const cloneGroupEntitlement = async () => {
    setCloneLoading(true);
    let sourceEntitlements = await getEntitlementMappings();
    if (sourceEntitlements?.length > 0) handleSaveClone(sourceEntitlements);
    else {
      Notify({
        alert: true,
        type: 'error',
        title: 'No entitlements present in the source group',
      });
    }
    setCloneLoading(false);
  };
  return (
    <>
      <ModalBox
        backdrop="static"
        show={showCloneUserGroupEntitlements}
        handleClose={onCloneUserGroupEntitlementsClose}
        showLoader={cloneLoading}
        modalTitle={
          <>
            Clone Group Entitlements
            <span className="clone-user-group-entitlements-subtitle">
              (Select Group to clone entitlements from)
            </span>
          </>
        }
      >
        <div className="container">
          <div className="row mt-2">
            <div className="col">
              <input
                type="text"
                id="search-filter-input"
                className="form-control search-box"
                placeholder="Search by group name"
                onInput={onFilterTextBoxChanged}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="col no-scroll">
              <AgGridComponent
                config={defaultConfig}
                gridStyle={sourceUserGroupsGridStyle}
                defaultColumnDef={defaultColdef}
                data={rowData}
                suppressRowClickSelection={false}
                columns={sourceUserGroupsColumns}
                onSelectionChanged={onSelectionChanged}
                onGridReady={onSourceUserGroupGridReadyHandler}
              />
            </div>
          </div>

          <Row className="modal-row">
            <Form>
              <Form.Group className="col-12 entitlements-clone-btn-body">
                <button
                  type="button"
                  disabled={!sourceUserGroupChanged}
                  className="btn btn-dark float-end btn-opacity"
                  onClick={cloneGroupEntitlement}
                >
                  Save Entitlements
                </button>
              </Form.Group>
            </Form>
          </Row>
        </div>
      </ModalBox>
    </>
  );
};

export default memo(CloneUserGroupEntitlements);
