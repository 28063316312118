import React, { memo, useEffect, useState } from 'react';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import { Endpoints, ReduxAction, maxPageSize } from './../../constants';
import HttpService from './../../services/http-service';
import { store } from './../../redux/store';
import { useDispatch } from 'react-redux';
const UsersDropdown = memo(
  ({
    contracts,
    callBackUsers,
    isGroupFilterApply,
    enableSelectAll = false,
  }) => {
    const [usersResult, setUsersResult] = useState();
    const [dropDownLoader, setDropDownLoader] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
      const reduceCallback = setTimeout(() => {
        getUsersDataSet();
      }, 1000);
      return () => clearTimeout(reduceCallback);
    }, [contracts]);

    const getUsersDataSet = async () => {
      try {
        let storeData = store.getState();
        let selectedEntitlementUser = storeData?.entitlementUsers;
        if (contracts?.length > 0 && contracts.some((el) => el.checked)) {
          let queryParamsUser = {
            PageSize: maxPageSize,
            id: new Date().getTime(),
            Field: 'contactName,contactId,email,contractNumber,groupId',
            Filter: `contractNumber in(${contracts
              .filter((x) => x.checked)
              .map((t) => (t.id ? '"' + t.id + '"' : ''))
              .join(',')})`,
            Sort: 'contactName:asc',
          };
          const usersResponse = await HttpService.get(
            Endpoints.userApi,
            queryParamsUser,
          );
          const userResp = usersResponse.data?.results;
          let responseData = userResp;
          if (isGroupFilterApply)
            responseData = userResp?.filter((x) => !x.groupId);
          let tempState = responseData?.map((el, i) => {
            return {
              id: i,
              checked:
                selectedEntitlementUser?.users?.length > 0
                  ? selectedEntitlementUser.users.some(
                      (x) => x.contactId === el.contactId,
                    )
                  : false,
              userLabel: `${el.contactName}  (${el.email})`,
              ...el,
            };
          });

          if (selectedEntitlementUser?.users?.length > 0) {
            tempState = tempState.sort((el) => {
              if (el.checked === true) return -1;
              else return 1;
            });
            dispatch({
              type: ReduxAction.selectedEntitlementUser,
              payload: [],
            });
          }

          // Set all group users as excluded list for dropdown
          if (isGroupFilterApply) {
            let nonUGLength = tempState.length;
            let filteredGroupUsers = [];

            for (let i = 0; i < userResp.length; i++) {
              let obj = userResp[i];
              if (obj.groupId) {
                obj = {
                  id: nonUGLength + i,
                  checked: false,
                  userLabel: `${obj.contactName}  (${obj.email})`,
                  disabled: true,
                  ...obj,
                };
                filteredGroupUsers.push(obj);
              }
            }
            tempState = tempState.concat(filteredGroupUsers);
          }

          setUsersResult(tempState);
        } else if (contracts) {
          setUsersResult([]);
        }
      } catch (err) {
        setDropDownLoader(false);
        setUsersResult([]);
      }
    };

    useEffect(() => {
      const updatedState = usersResult
        ?.filter((usersData) => usersData.checked === true)
        ?.map((filterusersData) => {
          return {
            contactName: `${filterusersData.contactName}  (${filterusersData.email})`,
            emailId: filterusersData.email,
            contactId: filterusersData.contactId,
            contractNumber: filterusersData.contractNumber,
          };
        });
      callBackUsers(updatedState);
    }, [usersResult]);

    const count =
      usersResult?.reduce(
        (counter, obj) => (obj?.checked === true ? (counter += 1) : counter),
        0,
      ) ?? 0;

    return (
      <MultiSelectDropdown
        enableSelectAll={enableSelectAll}
        searchKeys={['userLabel']}
        enableSearch={true}
        labelKeyName="userLabel"
        ddOptionsKey="contactId"
        dropDownLoader={dropDownLoader}
        ddOptions={usersResult}
        setddOptions={setUsersResult}
        ddName={'users'}
        ddDisabledMsg={
          'User is part of a group and can not be entitled separately. Entitlements must be updated at group level.'
        }
        ddPlaceHolder={
          <>
            Users
            <span className={count === 0 ? 'd-none' : 'd-inline'}>
              ({count})
            </span>
          </>
        }
      />
    );
  },
  (op, np) => op?.contracts === np?.contracts,
);

export default UsersDropdown;
