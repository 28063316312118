import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../components/Loader';
import { debounce } from '../../helpers/debounce';
import httpService from '../../services/http-service';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import {
  getFilterColumns,
  getGroupByColumns,
  getSortColumns,
  isGroupBy,
} from '../../sharedcomponents/ag-grid/ag-grid-helper';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import { Endpoints, Verbiage, maxExportSizeLimit } from './../../constants';
import store from './../../redux/store';
import './ChangeHistory.scss';
import { ReactComponent as ExportIcon } from './../../assets/icons/common/export.svg';
import { addContract } from './../../redux/actions';
import persistDataStore from './../../redux/persistStore';
import { getCSAContractsData } from './../../helpers/component-helper-methods';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import FileSaver from 'file-saver';
const ChangeHistory = () => {
  const [loading, setLoading] = useState(true);
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [isClientAdminUser, setIsClientAdminUser] = useState(false);
  const [contractsResult, setContractsResult] = useState([]);
  const gridRef = useRef();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const currentDate = new Date();
  const [daysToCheck, setDaysToCheck] = useState(89);
  const dispatch = useDispatch();
  const [agParamsData, setAgParamsData] = useState();
  const [enableExportButton, setEnableExportButton] = useState(false);
  const [titleExportButton, setTitleExportButton] = useState('');
  const customValueGetter = (params, columnId) => {
    // returns single string joining multiple keys
    let newValue = '';
    if (params) {
      let colId = columnId ?? params.column?.colId;
      switch (colId) {
        case 'contactName':
          if (
            params.data?.contactName ||
            (params.data?.firstName && params.data?.lastName)
          )
            newValue =
              params.data?.contactName ??
              params.data?.firstName + ' ' + params.data?.lastName;
          break;
        case 'deliveryPlatform':
          if (params.data?.distributor && params.data?.distributorPlatform)
            newValue =
              params.data?.distributor +
              ' : ' +
              params.data?.distributorPlatform +
              ' (' +
              params.data?.deliveryType +
              ')';
          break;
        case 'modifiedDate_1':
          newValue = params.data['modifiedDate'];
          break;
        default:
          newValue = params.data[colId];
      }
    }
    return newValue;
  };

  const defaultConfig = {
    editable: true,
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColums: true,
    suppressDragLeaveHidesColumns: true,
    enableCharts: false,
    sideBar: true,
    rowGroupPanelShow: false,
    overlayNoRowsTemplate: Verbiage.noData,
  };

  // Common column filter params
  const columnFilterParams = {
    filter: 'agTextColumnFilter',
    // filterParams: {
    //   buttons: ['reset', 'apply'],
    //   maxNumConditions: 1,
    // },
    enableRowGroup: false,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    // suppressCellSelection: true,
    enableValue: false, // disable value aggregation in column header
    valueGetter: customValueGetter,
    tooltipValueGetter: customValueGetter,
    initialFlex: 1,
  };

  const defaultColdef = {
    ...columnFilterParams,
    resizable: true,
    sortable: true,
  };

  const autoGroupColumnDef = {
    // column definition for grouped column
    ...columnFilterParams,
    enableRowGroup: false,
    lockPosition: 'left',
    sortable: true,
  };

  const historyGridColumns = [
    {
      headerName: 'contractNumber (hidden)',
      field: 'contractNumber',
      filterParams: {
        filterOptions: [
          'inRange',
          {
            displayKey: 'inRange',
            displayName: 'Range Between',
            predicate: ([filterValue], cellValue) => {
              return cellValue == null || cellValue < filterValue;
            },
          },
        ],
      },
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Last Modified Date (hidden)',
      field: 'modifiedDate',
      filterParams: {
        filterOptions: ['dateBetween'],
      },
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Name',
      headerTooltip: 'Name',
      field: 'contactName',
      enableRowGroup: true,
    },
    {
      headerName: 'Email ID',
      headerTooltip: 'Email ID',
      field: 'emailId',
    },
    {
      headerName: 'Package',
      headerTooltip: 'Package',
      field: 'packageName',
    },
    {
      headerName: 'Product',
      headerTooltip: 'Product',
      field: 'productName',
    },
    {
      headerName: 'Delivery Platform',
      headerTooltip: 'Delivery Platform',
      field: 'deliveryPlatform',
    },
    {
      headerName: 'Last Modified Date',
      headerTooltip: 'Last Modified Date',
      field: 'modifiedDate',
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    },
    {
      headerName: 'Last Modified By',
      headerTooltip: 'Last Modified By',
      field: 'modifiedBy',
    },
    {
      headerName: 'Status',
      headerTooltip: 'Status',
      field: 'status',
    },
  ];

  const updateDate = (date, noOfDays, action) => {
    if (action === 'add') {
      return date.setDate(date.getDate() + noOfDays);
    } else {
      return date.setDate(date.getDate() - noOfDays);
    }
  };

  useEffect(() => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo?.userInfo;
    if (storeUserInfo) {
      let isCSAUser = false;
      if (storeUserInfo?.SPGGroups?.includes('GRP_SFS_CSA')) {
        setDaysToCheck(365);
        setIsClientAdminUser(true);
        isCSAUser = true;
      }
      let storeContracts = storeData.contract;
      if (isCSAUser || !storeContracts?.contracts?.length > 0) {
        getContractsDataSet(storeUserInfo.email, isCSAUser);
      } else {
        setContractsResult(storeContracts.contracts);
        setLoading(false);
      }
    }
  }, [useSelector((x) => x.userInfo)]);

  // set selected contracts
  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      contractChangeHandler();
      if (!isClientAdminUser) dispatch(addContract(contractsResult));
    }, 1000);
    return () => clearTimeout(reduceCallback);
  }, [contractsResult]);

  const selectedCount = contractsResult?.reduce(
    (counter, obj) => (obj.checked === true ? (counter += 1) : counter),
    0,
  );

  const getContractsDataSet = async (userEmail, isCSAUser) => {
    userEmail = userEmail.toLowerCase();
    try {
      let queryParamsContract = {
        PageSize: 50000,
        Field: 'contractNumber,contractDescription,bulkEntitlementEnable',
        Filter: !isCSAUser ? `email:"${userEmail}"` : '',
      };
      const contractResponse = await httpService.get(
        Endpoints.contractApi,
        queryParamsContract,
      );

      let storeData = store.getState();
      let storeContracts = storeData.contract;
      let csaActionData =
        persistDataStore.getState()?.userActionData?.csaUserActionInfo;
      let otherContracts = storeContracts?.contracts;
      if (storeContracts?.contracts?.length <= 0) {
        otherContracts = await getCSAContractsData();
      }
      let tempState = contractResponse?.data?.results?.map((el) => {
        let isChecked = true;
        if (isCSAUser && csaActionData?.value) {
          isChecked =
            otherContracts?.length > 0
              ? otherContracts.some(
                  (x) => x.contractNumber == el.contractNumber && x.checked,
                )
              : false;
        }

        return {
          label:
            el.contractNumber +
            (el.contractDescription ? ' - ' + el.contractDescription : ''),
          id: el.contractNumber,
          checked: isChecked,
          bulkEntitlementEnable: el.bulkEntitlementEnable,
          ...el,
        };
      });
      setDropDownLoader(false);      
      setContractsResult(tempState);
      if (!isCSAUser) dispatch(addContract(tempState));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const onStartDateChangeHandler = (date) => {
    if (!date || (endDate && endDate < date)) {
      setStartDate(null);
    } else {
      setStartDate(date);
    }
  };

  const onEndDateChangeHandler = (date) => {
    if (!date || (startDate && startDate > date)) {
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  const getAllFilterModel = (colId, newModel) => {
    let currentModel = gridRef?.current?.api?.getFilterModel();
    currentModel[colId] = newModel;
    return { ...currentModel };
  };

  const dateToISOFormat = (date, addDays = 0) => {
    let parsedDate = new Date(date);
    // Add a day
    parsedDate.setDate(parsedDate.getDate() + addDays);
    // set time to 00:00 for date comparison only
    parsedDate = parsedDate.setUTCHours(0, 0, 0, 0);
    // convert to ISO standards
    parsedDate = new Date(parsedDate).toISOString();
    return parsedDate;
  };

  useMemo(() => {
    // Filter grid on start and end date selection
    if (!startDate || !endDate) {
      gridRef?.current?.api?.destroyFilter('modifiedDate');
    } else if (startDate && endDate) {
      let parsedStartDate = dateToISOFormat(startDate, 1);
      let parsedEndDate = dateToISOFormat(endDate, 2);

      // set filtermodel for aggrid hidden column
      let dateBetweenModel = {
        filter:
          ' >= "' +
          parsedStartDate +
          '" AND modifiedDate <= "' +
          parsedEndDate +
          '"',
        filterType: 'text',
        type: 'dateBetween',
      };
      let newFilterModel = getAllFilterModel('modifiedDate', dateBetweenModel);
      gridRef?.current?.api?.setFilterModel(newFilterModel);
    }
  }, [startDate, endDate]);

  const onFilterTextBoxChanged = useCallback(({ target }) => {
    let searchText = target.value;
    if (!searchText || searchText.length > 2) {
      gridRef?.current?.api?.onFilterChanged();
    }
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const onGridReadyHandler = (params) => {
    gridRef.current = params;
    gridRef?.current?.api?.setGridOption(
      'serverSideDatasource',
      getServerSideDatasource(),
    );
  };

  const getServerSideDatasource = () => {
    return {
      getRows: (params) => {
        try {
          let AgParams = params?.request;
          setEnableExportButton(false);
          setAgParamsData(AgParams);
          let contractFilterModel =
            AgParams?.filterModel['contractNumber']?.filter;
          if (
            contractFilterModel &&
            Object.keys(contractFilterModel)?.length > 0 &&
            contractFilterModel !== 'NoContractSelected'
          ) {
            let FilterColumns =
              'contactName,emailId,packageName,productName,deliveryPlatform,modifiedBy,status';
            let searchText = document.getElementById(
              'history-search-filter-input',
            ).value;
            let queryParams = {
              Filter: getFilterColumns(AgParams, 'modifiedDate', false),
              FilteredColumns: JSON.stringify(
                gridRef.current.api.getFilterModel(),
              ),
              Field: isGroupBy(AgParams) ? 'count(firstName)' : '',
              Sort: getSortColumns(AgParams, ''),
              PageSize: AgParams.endRow - AgParams.startRow,
              Page:
                AgParams.startRow / (AgParams.endRow - AgParams.startRow) + 1,
              groupBy: getGroupByColumns(AgParams),
              querySearch: searchText,
              searchColumns: searchText ? FilterColumns : '',
              dateFilter: getDateFilter(AgParams),
              id: String(Date.now()),
            };

            gridRef?.current?.api?.showLoadingOverlay();
            httpService
              .get(Endpoints.userEntitlementsAuditApi, queryParams)
              .then(({ data }) => {
                if (data) {
                  let rowData = data?.results ?? data?.aggResultValue;
                  if (rowData.length > 0) {
                    setEnableExportButton(true);
                    setTitleExportButton('Export to CSV');
                  } else {
                    setTitleExportButton(
                      'No records were returned based on the criteria specified',
                    );
                  }
                  rowData = rowData?.map((el) => {
                    let name = el.firstName + ' ' + el.lastName;
                    let createdDate = el.createdDate
                      ?.replace('T', ' ')
                      .split('.')[0];
                    let modifiedDate = el.modifiedDate
                      ?.replace('T', ' ')
                      .split('.')[0];
                    return { ...el, name, createdDate, modifiedDate };
                  });
                  params.success({
                    rowData: rowData,
                    rowCount: data?.metadata.count,
                  });
                  if (rowData?.length === 0)
                    gridRef?.current?.api?.showNoRowsOverlay();
                  else {
                    gridRef?.current?.api?.hideOverlay();
                  }
                }
              })
              .catch((err) => {
                params.fail();
                gridRef?.current?.api?.hideOverlay();
              });
          } else {
            params.success({
              rowData: [],
              rowCount: 0,
            });

            if (contractFilterModel === 'NoContractSelected') {
              gridRef?.current?.api?.showNoRowsOverlay();
            }
          }
        } catch (err) {
          params.fail();
        }
      },
    };
  };

  const contractChangeHandler = () => {
    if (gridRef.current && contractsResult && contractsResult.length > 0) {
      let filteredContracts = contractsResult.filter((x) => x.checked);
      if (filteredContracts.length == 0) {
        setTitleExportButton('Please select at least one contract to export');
      }
      let commonFilterType = {
        filterType: 'text',
        type: 'inRange',
      };
      let filterValue = {
        filter:
          filteredContracts.length !== 0
            ? filteredContracts
                .map((t) =>
                  t.contractNumber ? '"' + t.contractNumber + '"' : '',
                )
                .join(',')
            : 'NoContractSelected',
        ...commonFilterType,
      };
      gridRef?.current?.api?.setFilterModel({ contractNumber: filterValue });
      gridRef?.current?.api?.onFilterChanged();
    }
  };

  const getChildCount = useCallback((data) => {
    // Server side grouped row child count
    return data?.count;
  }, []);

  const getDateFilter = (AgParams) => {
    // Apply default filter for dates based on persona
    let filterColumns = getFilterColumns(AgParams, 'modifiedDate', true);
    if (filterColumns) {
      return filterColumns;
    } else {
      let today = new Date();
      let startDate = today.setDate(today.getDate() - daysToCheck);
      startDate = dateToISOFormat(startDate);
      let endDate = dateToISOFormat(Date.now(), 1);
      return (
        'modifiedDate >= "' +
        startDate +
        '" AND modifiedDate <= "' +
        endDate +
        '"'
      );
    }
  };
  const getDate = () => {
    let currentDate = new Date();
    return (
      currentDate.getDate() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getFullYear()
    );
  };

  const handleChangeHistoryExport = async () => {
    let AgParams = agParamsData;
    let contractFilterModel = AgParams?.filterModel['contractNumber']?.filter;
    if (
      contractFilterModel &&
      Object.keys(contractFilterModel)?.length > 0 &&
      contractFilterModel !== 'NoContractSelected'
    ) {
      if (enableExportButton) {
        setEnableExportButton(false);
        setTitleExportButton('Your download is in progress');
        Notify({
          alert: true,
          type: '',
          title: 'Your download is in progress',
        });

        let FilterColumns =
          'contactName,emailId,packageName,productName,deliveryPlatform,modifiedBy,status';
        let searchText = document.getElementById(
          'history-search-filter-input',
        ).value;
        let queryParams = {
          Filter: getFilterColumns(AgParams, 'modifiedDate', false),
          FilteredColumns: JSON.stringify(gridRef.current.api.getFilterModel()),
          Field: isGroupBy(AgParams) ? 'count(firstName)' : '',
          Sort: getSortColumns(AgParams, ''),
          PageSize: maxExportSizeLimit,
          Page: 1,
          groupBy: getGroupByColumns(AgParams),
          querySearch: searchText,
          searchColumns: searchText ? FilterColumns : '',
          dateFilter: getDateFilter(AgParams),
        };

        await httpService
          .get(Endpoints.userEntitlementsAuditExportApi, queryParams, {
            responseType: 'blob',
          })
          .then(async (response) => {
            const totalCount = response.headers['total-count'];
            if (totalCount > maxExportSizeLimit) {
              Notify({
                alert: true,
                type: 'error',
                dialogClassName: 'alert-export-success-max-width',
                title:
                  'Number of records for export (' +
                  totalCount +
                  ') are greater than the allowed limit (' +
                  maxExportSizeLimit +
                  '). Please adjust the export criteria to ensure that the number of records for export are within the allowed limit.',
              });
              return;
            }
            FileSaver.saveAs(response.data, 'ChangeHistory_' + getDate());
            Notify({
              alert: true,
              type: 'success',
              dialogClassName: 'alert-export-success-max-width',
              title: 'User Entitlements Exported Successfully',
            });
          })
          .catch((error) => {
            Notify({
              alert: true,
              type: 'error',
              title: 'Something went wrong',
            });
          })
          .finally(() => {
            setEnableExportButton(true);
            setTitleExportButton('Export to CSV');
          });
      }
    }
  };

  const getRowId = (params) => {
    if (params?.data && params.data.contactId) {
      // assign a unique ID to each data item
      return Object.entries(params.data).join(',');
    }
  };

  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="row mb-1">
            <div className="col-lg-9 col-md-8">
              <span id="user-location-header" className="page-header">
                Change History
              </span>
            </div>
            <div className="col-lg-3 col-md-4">
              <MultiSelectDropdown
                enableSelectAll={true}
                defaultSelectAll={true}
                labelKeyName="label"
                ddOptionsKey="id"
                dropDownLoader={dropDownLoader}
                ddOptions={contractsResult}
                setddOptions={setContractsResult}
                ddName={'contracts'}
                ddPlaceHolder={
                  selectedCount === contractsResult?.length
                    ? 'Contracts: All Contracts'
                    : `Contracts: (${selectedCount})`
                }
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-5 col-lg-6 col-sm-12">
              <input
                type="text"
                id="history-search-filter-input"
                className="form-control search-box"
                onInput={onFilterTextBoxChangedDebounce}
                placeholder="Search change history"
              />
            </div>
            <div className="col-md-6 col-lg-5 col-sm-11 date-picker-container">
              <DatePicker
                showIcon
                selected={startDate}
                className="col-sm-6"
                placeholderText="Start date"
                minDate={updateDate(new Date(), daysToCheck, 'sub')}
                maxDate={new Date()}
                isClearable
                onChange={onStartDateChangeHandler}
              />

              <DatePicker
                showIcon
                selected={endDate}
                className="col-sm-6"
                minDate={
                  startDate
                    ? startDate
                    : updateDate(currentDate, daysToCheck, 'sub')
                }
                maxDate={new Date()}
                isClearable
                placeholderText="End date"
                onChange={onEndDateChangeHandler}
              />
            </div>
            <div className="col-md-1 col-lg-1 col-sm-1 export-icon-block">
              <div className="action-item-bar">
                <span className="user-grid-icons icon-divider">
                  <ExportIcon
                    className={
                      enableExportButton ? 'icon-active' : 'cursor-default'
                    }
                    alt="Email Domain Export"
                    width="21"
                    title={titleExportButton}
                    onClick={handleChangeHistoryExport}
                  ></ExportIcon>
                </span>
              </div>
            </div>
          </div>

          <div className="ag-grid-table history">
            <AgGridComponent
              autoGroupColumnDef={autoGroupColumnDef}
              config={defaultConfig}
              defaultColumnDef={defaultColdef}
              // getRowId={getRowId}
              columns={historyGridColumns}
              getChildCount={getChildCount}
              onGridReady={onGridReadyHandler}
            />
            <div className="notes-text-label mt-1" id="notes-label">
              <span className="notes-asterisk-label">*</span>All times provided
              are in Coordinated Universal Time (UTC)
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChangeHistory;
