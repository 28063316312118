import React, { memo, useEffect, useState } from 'react';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import { Endpoints, maxPageSize } from '../../constants';
import httpService from '../../services/http-service';

const AccountsDropdown = memo(({ contracts, callBackAccounts }) => {
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [accountName, setAccountName] = useState();
  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      getAccounts(contracts);
    }, 800);
    return () => clearTimeout(reduceCallback);
  }, [contracts]);

  const getFilteredAccounts = (accounts) => {
    let uniqueAccounts = [];
    uniqueAccounts = accounts?.filter((el) => {
      if (!uniqueAccounts?.includes(el.accountId)) {
        uniqueAccounts?.push(el.accountId);

        return el;
      }
    });
    return uniqueAccounts;
  };

  const getAccounts = () => {
    if (contracts?.length > 0 && contracts.some((el) => el.checked)) {
      let queryAccountParams = {
        PageSize: maxPageSize,
        id: new Date().getTime(),
        filter: `contractNumber in(${contracts
          .filter((x) => x.checked)
          .map((t) => (t.id ? '"' + t.id + '"' : ''))
          .join(',')})`,
      };
      httpService
        .get(Endpoints.accountApi, queryAccountParams)
        .then((res) => {
          if (res) {
            let accountData = getFilteredAccounts(res.data?.results);
            let finalAccountData = accountData?.map((el, i) => {
              return {
                id: i,
                label: el.accountName,
                checked: false,
                ...el,
              };
            });
            setAccountName(finalAccountData);
          } else setAccountName([]);
        })
        .catch((err) => {
          setAccountName([]);
        })
        .finally(() => {
          setDropDownLoader(false);
        });
    } else setAccountName([]);
  };
  useEffect(() => {
    const updatedState = accountName
      ?.filter((accountsData) => accountsData.checked === true)
      ?.map((filterusersData) => {
        return {
          accountName: filterusersData.accountName,
          accountId: filterusersData.accountId,
          contractNumber: filterusersData.contractNumber,
        };
      });
    callBackAccounts(updatedState);
  }, [accountName]);

  const count =
    accountName?.reduce(
      (counter, obj) => (obj?.checked === true ? (counter += 1) : counter),
      0,
    ) ?? 0;

  return (
    <MultiSelectDropdown
      enableSelectAll={accountName?.length > 0}
      searchKeys={['accountName']}
      enableSearch={true}
      labelKeyName="label"
      ddOptionsKey="id"
      dropDownLoader={dropDownLoader}
      ddOptions={accountName}
      setddOptions={setAccountName}
      ddName={'accounts'}
      ddPlaceHolder={
        <>
          Accounts
          <span className={count === 0 ? 'd-none' : 'd-inline'}>({count})</span>
        </>
      }
    />
  );
});

export default AccountsDropdown;
