import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { loaderStyle, Verbiage } from '../../constants';
import './locations.scss';

const DuplicateAddressPopup = ({
  loading,
  handleClose,
  handleSubmit,
  submittedAddress,
  duplicateLocations,
}) => {
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        size="md"
        centered
        backdrop="static"
      >
        {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}

        <Modal.Header>
          <div className="header-title-txt">Review existing locations</div>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 w-100">
            <div className="address-wrapper">
              {/* What you entered block starts here */}
              <div className="address-suggestion">
                {Verbiage.duplicateAddressFound}
              </div>
              <br />
              <div className="address-suggestion">What you entered</div>
              <div className="address-block border-bottom-0">
                <label htmlFor="addressEntered">{submittedAddress}</label>
              </div>

              {/* Recommended block starts here */}
              <div className="address-suggestion mt-3">Existing</div>
              {/* Address 1 */}
              <div className="address-height-fix">
                {duplicateLocations?.length > 0 &&
                  duplicateLocations.map((response, index) => {
                    return (
                      <div key={index} className="address-block">
                        <label htmlFor={'addressRecommended' + index}>
                          {response.fullAddress}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <Row>
            <Col>
              <button
                className="btn btn-dark btn-opacity float-end"
                type="button"
                onClick={() => handleSubmit()}
              >
                SAVE
              </button>
              <button
                className="btn btn-outline-dark float-end me-2"
                type="button"
                onClick={handleClose}
              >
                CANCEL
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DuplicateAddressPopup;
