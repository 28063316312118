import React from 'react';
import { Provider } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router-dom';
import { store } from './redux/store';
import persistDataStore from './redux/persistStore';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import TokenRefreshWrapper from './components/auth/TokenRefreshWrapper';
// import { AuthProvider } from './contexts/JWTContext';
import routes from './routes';
import AlertBox from './sharedcomponents/Alert/Alerts';
import { UrlPath } from './constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const config = {
  clientId: window.config.clientId,
  issuer: window.config.issuer,
  redirectUri: window.location.origin + '/login/callback',
  postLogoutRedirectUri: window.location.origin + UrlPath.logout,
  scopes: ['openid', 'profile', 'email', 'groups', 'offline_access', 'api'],
  responseType: ['code', 'id_token', 'token'],
};

const oktaAuth = new OktaAuth(config);

const App = () => {
  const content = useRoutes(routes);
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Provider store={persistDataStore}>
        <Provider store={store}>
          {/* <AuthProvider> */}
          <TokenRefreshWrapper>
            {content}
            <AlertBox />
            <ToastContainer />
          </TokenRefreshWrapper>
          {/* </AuthProvider> */}
        </Provider>
      </Provider>
    </Security>
  );
};

export default App;
