import { ReduxAction } from './../../constants';
const initialState = {};

const UserGroupEntitlementReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReduxAction.updateUGEntitlement: {
      return {
        ...state,
        initialEntitlements: action.payload?.initialEntitlements,
        entitlementsToSave: action.payload?.entitlementsToSave,
        selectedEntitlements:
          action.payload?.selectedEntitlements ?? state?.selectedEntitlements,
        isGroupCloneAction:
          action.payload?.isGroupCloneAction ?? state?.isGroupCloneAction,
      };
    }

    case ReduxAction.updateUGProductEntitlement: {
      return {
        ...state,
        initialProductEntitlements: action.payload?.initialProductEntitlements,
        productEntitlementsToSave: action.payload?.productEntitlementsToSave,
      };
    }

    case ReduxAction.updateUGManageUser: {
      return {
        ...state,
        initialUsers: action.payload?.initialUsers ?? state?.initialUsers,
        manageUserSelection:
          action.payload?.manageUserSelection ?? state?.manageUserSelection,
      };
    }

    case ReduxAction.updateUG_API_Flag: {
      return {
        ...state,
        entitlementAPISuccess:
          action.payload?.entitlementAPISuccess ?? state?.entitlementAPISuccess,
        groupUsersAPISuccess:
          action.payload?.groupUsersAPISuccess ?? state?.groupUsersAPISuccess,
      };
    }
    default:
      return state;
  }
};
export default UserGroupEntitlementReducer;
