import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Endpoints,
  GenericError,
  maxPageSize,
  ReduxAction,
  queryParamFields,
} from '../../../constants';
import httpService from '../../../services/http-service';
import Loader from './../../../components/Loader';
import store from './../../../redux/store';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import ManageUserIds from '../../user-details/manage-user-ids/manage-user-ids';
import { useDispatch } from 'react-redux';

const EndUserManageUserIds = () => {
  const [loading, setLoading] = useState(true);
  const [distributorDataSet, setDistributorDataSet] = useState();
  const [userDetails, setUserDetails] = useState();
  const [endUserDistributors, setEndUserDistributors] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo;

    if (storeUserInfo && storeUserInfo.userInfo) {
      getDeliveryPlatformData();
      getUserDetails(storeUserInfo.userInfo.email);
    }
  }, [useSelector((x) => x.userInfo)]);

  useEffect(() => {
    if (userDetails) {
      getUserEntitlements(userDetails.contactId);
    }
  }, [userDetails]);

  const getUserDetails = (userEmail) => {
    let queryParams = {
      Filter: `email:"${userEmail.toLowerCase()}"`,
      Field:
        'contactName,contactId,groupid,groupName,contractNumber,email,phone,department,jobFunction,designation,isEmailOptOut,emailProcessed,account,accountId,officeLocation,officeLocationId,entitled,modifiedDate',
      id: String(Date.now()),
    };
    httpService
      .get(Endpoints.userApi, queryParams)
      .then(({ data }) => {
        let results = data?.results[0];
        setUserDetails(results);
      })
      .catch(() => {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      });
  };

  const getUserEntitlements = async (contactId) => {
    let queryParams = {
      pageSize: 5000,
      id: new Date().getTime(),
      filter: `contactId:"${contactId}"`,
    };

    try {
      const entitlementsResponse = await httpService.get(
        Endpoints.userentitlementsApi,
        queryParams,
      );
      let userEntitlements = entitlementsResponse?.data?.results || [];

      const validStatuses = new Set([
        'Active',
        'Pending Active',
        'Pending Approval',
      ]);

      userEntitlements = userEntitlements.filter((entitlement) => {
        return validStatuses.has(entitlement.status);
      });

      userEntitlements = userEntitlements.filter(
        (v, i, a) =>
          a.findIndex(
            (t) =>
              t.distributor === v.distributor &&
              t.distributorPlatform === v.distributorPlatform,
          ) === i,
      );
      const distributorsResponse = await httpService.get(
        Endpoints.distributorUserApi,
        queryParams,
      );
      let userDistributors = distributorsResponse?.data?.results || [];

      const updatedEntitlements = userEntitlements.map((entitlement) => {
        const matchingDistributor = userDistributors.find(
          (distributor) =>
            distributor.distributorId == entitlement.distributorId,
        );
        if (matchingDistributor) {
          return {
            ...entitlement,
            userId: matchingDistributor.userId,
            userIdFormat: matchingDistributor.userIdFormat,
          };
        }
        return entitlement;
      });

      setEndUserDistributors(updatedEntitlements);
      setLoading(false);
    } catch (err) {
      Notify({
        alert: true,
        type: 'error',
        title: GenericError.somethingWentWrong,
      });
    }
  };

  const getDeliveryPlatformData = (signal) => {
    let storeData = store.getState();
    let storeDistributors = storeData?.distributors;
    if (storeDistributors?.userDistributors) {
      setDistributorDataSet(storeDistributors.userDistributors);
    } else {
      let queryParams = {
        pageSize: maxPageSize,
        id: new Date().getTime(),
        Field: queryParamFields.contractPackagesAPIFields,
      };
      httpService
        .get(Endpoints.contractPackagesApi, queryParams, { signal })
        .then((res) => {
          if (res) {
            let distributorData = res.data?.results.map(
              ({
                distributor,
                distributorId,
                distributorPlatform,
                deliveryType,
                packageId,
                productComponentId,
                platformCategories,
              }) => ({
                distributor,
                distributorId,
                distributorPlatform,
                deliveryType,
                packageId,
                productComponentId,
                platformCategories,
              }),
            );
            distributorData = distributorData.filter(
              (e, i) =>
                distributorData.findIndex(
                  (a) => a['distributorId'] === e['distributorId'],
                ) === i,
            );
            let metadata = res?.data?.metadata;
            metadata.count = distributorData?.length;
            let finalDistributorData = [];
            finalDistributorData.metadata = metadata;
            finalDistributorData.results = distributorData;
            setDistributorDataSet(finalDistributorData);
            dispatch({
              type: ReduxAction.distributors,
              payload: {
                distributors: finalDistributorData,
              },
            });
          }
        })
        .catch((err) => {
          setDistributorDataSet([]);
        });
    }
  };

  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="row">
            <div className="col-9">
              <span
                id="user-location-header"
                className="page-header breadcrumb-nav-wrapper"
              >
                <span>Manage User IDs</span>
              </span>
            </div>
          </div>
          <div className="row">
            {endUserDistributors && (
              <ManageUserIds
                distributorDataSet={distributorDataSet}
                editData={userDetails}
                setLoading={setLoading}
                isEndUser={true}
                endUserDistributors={endUserDistributors}
                setEndUserDistributors={setEndUserDistributors}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default EndUserManageUserIds;
