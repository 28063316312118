import React, { memo, useEffect, useState } from 'react';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import { Endpoints, maxPageSize } from '../../constants';
import httpService from '../../services/http-service';

const LocationsDropdown = memo(({ contracts, callBackLocations }) => {
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [officeLocationName, setOfficeLocationName] = useState();

  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      getLocations(contracts);
    }, 800);
    return () => clearTimeout(reduceCallback);
  }, [contracts]);

  const getDuplicateLocationsWithContractNum = (locations) => {
    let uniqueLocations = [];
    let duplicateLocations = [];
    let tempLocationsWithContractNum = locations?.filter((el) => {
      if (!uniqueLocations?.includes(el.officeLocationId)) {
        uniqueLocations?.push(el.officeLocationId);

        return el;
      } else {
        duplicateLocations?.push(el.officeLocationId);
      }
    });

    tempLocationsWithContractNum = locations?.filter((el) => {
      if (duplicateLocations?.includes(el.officeLocationId)) {
        el.officeLocationName =
          el.officeLocationName + ' (' + el.contractNumber + ')';
      }
      return el;
    });
    return tempLocationsWithContractNum;
  };

  const getLocations = () => {
    if (contracts?.some((el) => el.checked)) {
      const checkedContracts = contracts
        .filter((x) => x.checked)
        .map((t) => (t.id ? `"${t.id}"` : ''))
        .join(',');
      const queryLocationParams = {
        PageSize: maxPageSize,
        id: Date.now(),
        field: 'contractNumber,officeLocationName,officeLocationId',
        filter: `contractNumber in(${checkedContracts})`,
      };

      httpService
        .get(Endpoints.accountApi, queryLocationParams)
        .then((res) => {
          if (res?.data?.results) {
            const officeLocationData = getDuplicateLocationsWithContractNum(
              res.data.results,
            );
            const finalOfficeLocationData = officeLocationData.map((el, i) => ({
              id: i,
              label: el.officeLocationName,
              checked: false,
              ...el,
            }));
            setOfficeLocationName(finalOfficeLocationData);
          } else {
            setOfficeLocationName([]);
          }
        })
        .catch(() => {
          setOfficeLocationName([]);
        })
        .finally(() => {
          setDropDownLoader(false);
        });
    } else {
      setOfficeLocationName([]);
    }
  };

  useEffect(() => {
    const updatedState = officeLocationName
      ?.filter((locationsData) => locationsData.checked === true)
      ?.map((filterusersData) => {
        return {
          officeLocationName: filterusersData.officeLocationName,
          officeLocationId: filterusersData.officeLocationId,
          contractNumber: filterusersData.contractNumber,
        };
      });
    callBackLocations(updatedState);
  }, [officeLocationName]);

  const count =
    officeLocationName?.reduce(
      (counter, obj) => (obj?.checked === true ? (counter += 1) : counter),
      0,
    ) ?? 0;

  return (
    <MultiSelectDropdown
      enableSelectAll={officeLocationName?.length > 0}
      searchKeys={['officeLocationName']}
      enableSearch={true}
      labelKeyName="label"
      ddOptionsKey="id"
      dropDownLoader={dropDownLoader}
      ddOptions={officeLocationName}
      setddOptions={setOfficeLocationName}
      ddName={'locations'}
      ddPlaceHolder={
        <>
          Locations
          <span className={count === 0 ? 'd-none' : 'd-inline'}>({count})</span>
        </>
      }
    />
  );
});

export default LocationsDropdown;
