import React from 'react';
import copy_link_icon from '../../assets/icons/common/copy-icon.svg';
import './CopyLinkCell.scss';

const CopyLinkCell = ({ value }) => {
  const [copied, setCopied] = React.useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };
  return (
    <>
      <span>
        <img
          onClick={copyToClipboard}
          className="cursor-pointer me-3"
          src={copy_link_icon}
          width={18}
          height={18}
          title="Copy Link"
        />
        {copied && <span className="tooltip-link">Link Copied</span>}
      </span>
    </>
  );
};

export default CopyLinkCell;
