import persistDataStore from './../redux/persistStore';
import store from '../redux/store';
import httpService from './../services/http-service';
import { Endpoints, ReduxAction } from './../constants';

export const getCSAContractsData = async () => {
  let csaActionData =
    persistDataStore.getState()?.userActionData?.csaUserActionInfo;
  let queryParamsContract = {
    PageSize: 5000,
    Field: 'contractNumber',
  };
  if (csaActionData?.type && csaActionData?.value) {
    switch (csaActionData?.type) {
      case 'account': {
        queryParamsContract.Filter = `accountId:"${csaActionData?.value}"`;

        const contractResponse = await httpService.get(
          Endpoints.locationApi,
          queryParamsContract,
        );
        let tempState = contractResponse.data?.results?.map((el, i) => {
          return {
            label: el.contractNumber,
            id: el.contractNumber,
            checked: true,
            ...el,
          };
        });
        return tempState;
      }

      case 'contract': {
        let queryParams = {
          PageSize: 5000,
          Field: 'contractNumber',
          Filter: `contractNumber:"${csaActionData?.value}"`,
        };
        const contractResponse = await httpService.get(
          Endpoints.locationApi,
          queryParams,
        );
        let csaSelectedContract = [
          {
            label: csaActionData?.value,
            id: csaActionData?.value,
            checked: true,
            contractNumber: csaActionData?.value,
          },
        ];
        return csaSelectedContract;
      }
      case 'user': {
        let queryParams = {
          PageSize: 5000,
          Field: 'contractNumber',
          Filter: `email:"${csaActionData?.value}"`,
        };
        const usersContractResponse = await httpService.get(
          Endpoints.userApi,
          queryParams,
        );
        let tempState = usersContractResponse.data?.results?.map((el, i) => {
          return {
            label: el.contractNumber,
            id: el.contractNumber,
            checked: true,
            ...el,
          };
        });
        return tempState;
      }
    }
  }
  return [];
};

export const getDbConfigData = async (dispatch) => {
  try {
    // Get the dbConfig data from the DB and save it in store
    let dbConfigData = store.getState()?.dbConfig;
    if (Object.keys(dbConfigData).length > 0) {
      return dbConfigData;
    }

    let queryParams = {
      keyItem: `JOBFUNCTIONCOLLECTION, DESIGNATIONCOLLECTION, SFS_EXPORT_LIMIT`,
    };
    let dbConfigResponse = await httpService.get(
      Endpoints.configurationApi,
      queryParams,
    );

    let newDbConfig = {
      jobFunctionCollection: [],
      designationCollection: [],
      exportLimit: 25000,
    };

    dbConfigResponse?.data?.results?.map((item) => {
      switch (item.keyItem) {
        case 'JOBFUNCTIONCOLLECTION':
          newDbConfig.jobFunctionCollection = JSON.parse(item.keyValue);
          break;
        case 'DESIGNATIONCOLLECTION':
          newDbConfig.designationCollection = JSON.parse(item.keyValue);
          break;
        case 'SFS_EXPORT_LIMIT':
          newDbConfig.exportLimit = JSON.parse(item.keyValue);
        default:
          break;
      }
    });

    dispatch({
      type: ReduxAction.dbConfig,
      payload: newDbConfig,
    });

    return newDbConfig;
  } catch (err) {}
};
