import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Loader from '../../components/Loader';
import { store } from '../../redux/store';
import httpService from '../../services/http-service';
import DropdownInput from '../../sharedcomponents/DropdownInput/DropdownInput';
import TextFieldInput from '../../sharedcomponents/TextFieldInput/TextFieldInput';
import { Endpoints, loaderStyle, ReduxAction, UserId } from './../../constants';
import './locations.scss';

const LocationAddEdit = ({
  show,
  handleClose,
  contracts,
  locationDetails,
  onSaveClick,
  loading,
  sourceType,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [initialLocatonValue, setInitialLocatonValue] = useState();
  const [accountsData, setAccountsData] = useState([]);
  const [countryStateInfo, setCountryStateInfo] = useState({});
  let locationInitialValue = {
    officeLocationId: '-1',
    accountId: '',
    officeLocationName: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    isDeleted: 0,
    userId: UserId,
    contractNumber: '',
  };
  const dispatch = useDispatch();
  contracts = contracts ?? [];

  const SubmitHandler = (value) => {
    if (sourceType === 'EndUser') {
      value.accountName = accountsData?.filter(
        (el) => el.accountId === value.accountId,
      )[0]?.accountName;
    }

    // assign hc_id based on selected account
    const matchingAccount = accountsData.find(
      (account) => account.accountId === value.accountId,
    );
    // It will restrict user on editing of the below fields
    if (
      isEdit &&
      (value.city !== locationDetails.city ||
        value.postalCode !== locationDetails.postalCode ||
        value.country !== locationDetails.country ||
        value.state !== (locationDetails.state || ''))
    )
      return;

    onSaveClick(value, isEdit);
  };

  const validate = Yup.object({
    contractNumber: Yup.string().required(
      'Please select a contract number to proceed',
    ),
    accountId: Yup.string().required('Please select an account to proceed'),
    address_1: Yup.string()
      .trim()
      .max(255, 'Address 1 must be 255 characters or less')
      .required('Please enter address 1 to proceed'),
    address_2: Yup.string()
      .trim()
      .max(255, 'Address 2 must be 255 characters or less'),
    city: Yup.string()
      .trim()
      .max(100, 'City must be 100 characters or less')
      .required('Please select a city to proceed'),
    state: Yup.string().when([], {
      is: () =>
        countryStateInfo.filteredState &&
        countryStateInfo.filteredState.length > 0,
      then: Yup.string().trim().required('Please select a state to proceed'),
      otherwise: Yup.string(),
    }),
    postalCode: Yup.string()
      .trim()
      .max(20, 'Post code must be 20 characters or less')
      .required('Please select a post code to proceed'),
    country: Yup.string().trim().required('Please select a country to proceed'),
  });

  useEffect(async () => {
    try {
      // Use redux store to get country state list, if not available then fetch from API and store in redux
      let storeData = store.getState();
      let countryStateList = storeData?.locationTab?.countryStateList;
      if (!countryStateList) {
        countryStateList = await getCountryStateList();
      }

      let filteredCountry = countryStateList
        .map((el) => el.country)
        .sort(Intl.Collator().compare);

      let filteredState = [];

      setCountryStateInfo({
        CS_main: countryStateList,
        filteredCountry,
        filteredState,
      });
    } catch (error) {}
  }, [useSelector((x) => x.userInfo)]);

  const getCountryStateList = async () => {
    let tempCountryStateInfo = {};
    const configurationURI =
      sourceType === 'EndUser'
        ? Endpoints.userRegistrationConfigurationApi
        : Endpoints.configurationApi;
    let queryParams = {
      keyItem: `COUNTRY_STATE`,
    };
    await httpService
      .get(configurationURI, queryParams)
      .then((res) => {
        const response = res?.data?.results;
        response.map((item) => {
          if (item.keyItem === 'COUNTRY_STATE') {
            let parseList = JSON.parse(item.keyValue);

            tempCountryStateInfo.countryStateList = parseList;
          }
        });
        dispatch({
          type: ReduxAction.countryStateList,
          payload: tempCountryStateInfo,
        });
      })
      .catch((err) => {});

    return tempCountryStateInfo.countryStateList;
  };

  const getLocationValues = () => {
    return {
      officeLocationId: locationDetails.officeLocationId,
      accountId: locationDetails.accountId,
      contractNumber: locationDetails.contractNumber,
      officeLocationName: locationDetails.officeLocationName ?? '',
      address_1: locationDetails.address_1 ?? '',
      address_2: locationDetails.address_2 ?? '',
      city: locationDetails.city ?? '',
      state: locationDetails.state ?? '',
      postalCode: locationDetails.postalCode ?? '',
      country: locationDetails.country ?? '',
      isDeleted: locationDetails.isDeleted ?? 0,
      userId: locationDetails.userId ?? UserId,
    };
  };
  const locationFormik = useFormik({
    initialValues: isEdit ? getLocationValues() : locationInitialValue,
    validationSchema: validate,
    onSubmit: SubmitHandler,
    enableReinitialize: true,
  });

  const getApiUrl = () => {
    return sourceType === 'EndUser'
      ? Endpoints.userRegistrationAccountApi
      : Endpoints.accountApi;
  };
  const getAccounts = (contractNumber) => {
    if (contractNumber) {
      setAccountsData();
      let queryLocationParams = {
        PageSize: 5000,
        field: 'accountId,accountName',
        filter: `contractNumber:"${contractNumber}"`,
      };
      httpService
        .get(getApiUrl(), queryLocationParams)
        .then((res) => {
          setAccountsData(res.data?.results);
        })
        .catch((err) => {
          setAccountsData([]);
        });
    }
  };

  useEffect(() => {
    let isEditValue =
      locationDetails != null && locationDetails.officeLocationId != null
        ? true
        : false;
    setIsEdit(isEditValue);
    setInitialLocatonValue(getLocationValues());
    // locationFormik.resetForm();
    if (isEditValue && locationDetails.contractNumber) {
      getAccounts(locationDetails.contractNumber);
    }
    if (
      sourceType === 'EndUser' &&
      contracts.length > 0 &&
      accountsData?.length == 0
    ) {
      getAccounts(contracts[0]?.id);
      let contractData = {
        target: {
          id: 'contractNumber',
          value: contracts[0]?.id,
        },
      };
      locationFormik.handleChange(contractData);
    }
    locationFormik.setFieldValue(locationDetails);
  }, [locationDetails]);

  useEffect(() => {
    try {
      if (isEdit) {
        if (
          JSON.stringify(initialLocatonValue) !==
          JSON.stringify(locationFormik.values)
        ) {
          setIsValueChanged(true);
        } else {
          setIsValueChanged(false);
        }
      }

      if (locationFormik.values.country) {
        setStateValues(locationFormik.values.country);
      }
    } catch (error) {}
  }, [locationFormik.values]);

  const onChangeHandler = (val, name) => {
    let value;

    if (name === 'contractNumber' && val && typeof val === 'object') {
      value = val.value;
    } else {
      value = val[name] ?? val;
    }

    let obj = {
      target: { name: name, value: value },
    };
    locationFormik.handleChange(obj);
    if (name === 'contractNumber') {
      resetAccountDropdown();
      if (value && name === 'contractNumber') {
        getAccounts(value);
      } else {
        setAccountsData();
      }
    }

    if (name === 'country') {
      locationFormik.setFieldValue('state', '');
    }
  };

  const setStateValues = (countryName) => {
    let foundCountry = countryStateInfo?.CS_main?.find(
      (el) => el.country === countryName,
    );
    let stateList = foundCountry?.states?.map((el) => el?.state ?? el) ?? [];
    stateList = stateList.sort((a, b) =>
      a.localeCompare(b, undefined, { sensitivity: 'base' }),
    );

    setCountryStateInfo({
      ...countryStateInfo,
      filteredState: stateList,
    });
  };

  const resetAccountDropdown = () => {
    let accountData = {
      target: {
        id: 'accountId',
        value: '',
      },
    };
    locationFormik.handleChange(accountData);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
      >
        {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}

        <Modal.Header closeButton>
          <span className="location-form-title">
            {isEdit ? 'Location Details' : 'Add a New Location'}
          </span>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={locationFormik.handleSubmit} className="row mb-0">
            <DropdownInput
              formLabel="Contract Number"
              name="contractNumber"
              containerClass={sourceType === 'EndUser' ? 'd-none' : ''}
              dropdownOptions={contracts.map((contract) => ({
                label: contract.label,
                value: contract.contractNumber,
              }))}
              formik={locationFormik}
              ddPlaceHolder={
                locationFormik.values.contractNumber !== '' &&
                contracts?.length > 0
                  ? contracts.find(
                      (el) =>
                        el.contractNumber ===
                        locationFormik.values.contractNumber,
                    )?.label || 'Select'
                  : 'Select'
              }
              labelKeyName="label"
              valueKeyName="value"
              ddName={'contracts'}
              onChangeHandler={(e) => onChangeHandler(e, 'contractNumber')}
              addAsterisk
            />
            <DropdownInput
              formLabel="Account"
              name="accountId"
              dropdownOptions={accountsData}
              formik={locationFormik}
              ddPlaceHolder={
                locationFormik.values.accountId !== '' &&
                accountsData?.length > 0
                  ? accountsData?.filter(
                      (el) => el.accountId === locationFormik.values.accountId,
                    )[0]?.accountName
                  : 'Select'
              }
              labelKeyName="accountName"
              ddName={'account'}
              onChangeHandler={(e) => onChangeHandler(e, 'accountId')}
              addAsterisk
            />
            {/* <TextFieldInput
              formLabel="Location Name"
              placeholder="Location Name"
              id="officeLocationName"
              {...locationFormik.getFieldProps('officeLocationName')}
              formik={locationFormik}
              addAsterisk
              autoComplete="off"
            /> */}
            <TextFieldInput
              formLabel="Address 1"
              placeholder="Address 1"
              id="address_1"
              {...locationFormik.getFieldProps('address_1')}
              formik={locationFormik}
              autoComplete="off"
              addAsterisk
            />
            <TextFieldInput
              formLabel="Address 2"
              placeholder="Address 2"
              id="address_2"
              {...locationFormik.getFieldProps('address_2')}
              formik={locationFormik}
              autoComplete="off"
            />
            <TextFieldInput
              formLabel="City"
              placeholder="City"
              id="city"
              disabled={isEdit}
              {...locationFormik.getFieldProps('city')}
              formik={locationFormik}
              autoComplete="off"
              addAsterisk
            />
            <TextFieldInput
              formLabel="Post Code"
              placeholder="Post Code"
              id="postalCode"
              disabled={isEdit}
              {...locationFormik.getFieldProps('postalCode')}
              formik={locationFormik}
              autoComplete="off"
              addAsterisk
            />
            <DropdownInput
              formLabel="Country"
              placeholder="Country"
              enableSearch={true}
              searchKeys={[]}
              id="country"
              disabled={isEdit}
              dropdownOptions={countryStateInfo?.filteredCountry}
              ddName={'country'}
              ddPlaceHolder={
                locationFormik.values.country !== ''
                  ? locationFormik.values.country
                  : 'Select'
              }
              {...locationFormik.getFieldProps('country')}
              onChangeHandler={(e) => onChangeHandler(e, 'country')}
              formik={locationFormik}
              addAsterisk
              autoComplete="off"
            />
            <DropdownInput
              formLabel="State"
              placeholder="State"
              enableSearch={true}
              searchKeys={[]}
              id="state"
              disabled={isEdit}
              dropdownOptions={countryStateInfo?.filteredState}
              ddName={'state'}
              ddPlaceHolder={
                locationFormik.values.state !== ''
                  ? locationFormik.values.state
                  : 'Select'
              }
              {...locationFormik.getFieldProps('state')}
              onChangeHandler={(e) => onChangeHandler(e, 'state')}
              formik={locationFormik}
              customDropdownMessage="No states under the selected country"
              addAsterisk={(countryStateInfo?.filteredState ?? []).length > 0}
              autoComplete="off"
            />
            <Row className="pe-0">
              <Form.Group className="pe-0 mb-1 user-save-btn-body">
                <button
                  type="Submit"
                  className="btn btn-dark float-end btn-opacity"
                  disabled={isEdit && !isValueChanged}
                >
                  {isEdit ? 'Update Location' : 'Save Location'}
                </button>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LocationAddEdit;
