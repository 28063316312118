import React, { useState, useEffect } from 'react';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { Chips } from 'primereact/chips';
import './manage-email-domains.scss';
import {
  Endpoints,
  GenericError,
  Verbiage,
  loaderStyle,
} from './../../constants';
import { useFormik } from 'formik';
import Loader from '../../components/Loader';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import httpService from '../../services/http-service';
import { Notify } from './../../sharedcomponents/Alert/Notify';

const AddUserRegistration = ({ handleClose, clickedRowData }) => {
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [inValidEmail, setInValidEmail] = useState(false);
  const [submittedEmails, setSubmittedEmails] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialValue, setInitialValue] = useState({
    emails: [],
    emailDetails: [],
  });
  const userEmailLimit = 200;
  const [exceedMaxLimit, setExceedMaxLimit] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const sourceUsersGridStyle = { height: '35vh' };
  const sourceColumns = [
    {
      headerName: 'Email',
      field: 'email',
      tooltipField: 'email',
      initialFlex: 1,
      resizable: true,
      cellClass: (params) => {
        return params.data.status === 'Valid'
          ? 'success-bulk-upload-row'
          : 'error-bulk-upload-row';
      },
    },
    {
      headerName: 'Notes',
      field: 'error',
      tooltipField: 'error',
      initialFlex: 1,
      resizable: true,
      cellClass: (params) => {
        return params.data.status === 'Valid'
          ? 'success-bulk-upload-row'
          : 'error-bulk-upload-row';
      },
    },
  ];
  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
  };
  const defaultColdef = {
    enableRowGroup: false,
    enablePivot: false,
    enableValue: false,
    resizable: true,
  };

  const getEmailDomain = async () => {
    if (clickedRowData?.contractNumber) {
      const queryParamsContract = {
        PageSize: 5000,
        Field: 'contractNumber,emailDomain',
        Filter: `contractNumber:"${clickedRowData.contractNumber}"`,
      };
      const contractResponse = await httpService.get(
        Endpoints.contractApi,
        queryParamsContract,
      );
      if (contractResponse?.data?.results?.length > 0) {
        return contractResponse.data.results[0];
      }
    }
    return null;
  };

  useEffect(() => {}, []);

  const SubmitHandler = async (value) => {
    try {
      setLoading(true);
      const emailList = value.emails;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let emailValues = emailList.map((item) => {
        const validateEmail = emailRegex.test(item);
        return {
          email: item,
          status: validateEmail ? 'Valid' : 'InValid',
          error: validateEmail ? 'Email sent' : 'Invalid Email',
        };
      });

      let validateDomains = clickedRowData?.emailDomain
        ? clickedRowData.emailDomain.split(',')
        : (await getEmailDomain())?.emailDomain?.split(',');
      if (validateDomains?.length > 0) {
        emailValues
          .filter((data) => data.status == 'Valid')
          .forEach((item) => {
            if (!validateDomains.some((d) => item.email.endsWith(d))) {
              item.status = 'InValid';
              item.error = 'Email domain not allowed';
            }
          });
      } else {
        emailValues
          .filter((data) => data.status == 'Valid')
          .forEach((item) => {
            item.status = 'InValid';
            item.error = 'Email domain not allowed';
          });
      }

      setSubmittedEmails(
        emailValues.every((data) => data.status === 'InValid')
          ? 'None'
          : 'Partial',
      );
      formik.setFieldValue('emailDetails', emailValues);
      setErrorData(emailValues.filter((data) => data.status == 'InValid'));
      setInValidEmail(emailValues.some((item) => item.status === 'InValid'));

      //send the valid list of emails
      sendEmailToInvites(emailValues);
    } catch (err) {
      setLoading(false);
    }
  };

  const sendEmailToInvites = (inviteesList) => {
    const inviteesEmails = inviteesList
      .filter((invitee) => invitee.status === 'Valid')
      .map((invitee) => invitee.email)
      .join(',');

    if (inviteesEmails?.length > 0) {
      let emailData = [];
      let mail_body = '';
      let mail_to_address = 'donotreply@spglobal.com';
      let mail_cc_address = '';
      let mail_bcc_address = inviteesEmails;
      let mail_from_address = 'donotreply@spglobal.com';

      if (clickedRowData.uniqueLink) {
        clickedRowData.onboardingLink = clickedRowData.uniqueLink;
      }

      mail_body = `
      <div style="font-size: 14; font-family: Arial">Dear User,</p>
      <p>You have been invited to register to Commodity Insights Subscription Manager application. Commodity Insights Subscription Manager provides you with a desktop solution to access your subscription and manage entitlements in one central location. To register, please follow the link below</p>
      <p>${clickedRowData.onboardingLink}</p>
      <p>Sincerely,<br />
      S&P Global Support<br /></p></div>
      `;

      let mail_subject = 'CI Subscription Manager - Registration Invite';
      emailData.push({
        MAIL_FROM_ADDRESS: mail_from_address,
        MAIL_TO_ADDRESS: mail_to_address,
        MAIL_CC_ADDRESS: mail_cc_address,
        MAIL_BCC_ADDRESS: mail_bcc_address,
        MAIL_BODY: mail_body,
        MAIL_SUBJECT: mail_subject,
      });
      setLoading(true);
      httpService
        .post(Endpoints.sendEmailApi, emailData[0])
        .then((response) => {
          if (response.status === 200) {
            saveSentEmailsInvitees(inviteesList, inviteesEmails);
          } else setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        });
    } else setLoading(false);
  };

  const saveSentEmailsInvitees = (inviteesList, inviteesEmails) => {
    const emails = inviteesEmails.split(',').map((email) => email.trim());

    const data = {
      emails: emails,
      userId: localStorage.userID,
    };

    httpService
      .post(Endpoints.saveEndUserApi, data)
      .then((response) => {
        if (
          response.status === 200 ||
          (response.status === 201 &&
            inviteesList.every((data) => data.status === 'Valid'))
        ) {
          handleClose();
          Notify({
            alert: true,
            type: 'success',
            title: Verbiage.emailSent,
          });
        }
      })
      .catch((err) => {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    onSubmit: SubmitHandler,
  });

  const onChipKeyDown = (e) => {
    try {
      if (
        e.code === 'Tab' ||
        e.code === 'Comma' ||
        e.code === 'Space' ||
        e.code === 'Enter'
      ) {
        e.preventDefault();
        // add new value and set duplicate flag
        let newValue = e.target.value;
        const splitResult = newValue.split(/[\t, ]+/);
        if (splitResult.length <= userEmailLimit) {
          setExceedMaxLimit(false);
          let chipValues = [...formik.values.emails];
          splitResult.forEach((item) => {
            let duplicateValue = false;
            chipValues.forEach((el) => {
              if (!duplicateValue) {
                if (el === item) {
                  duplicateValue = true;
                }
              } else return;
            });
            //  clear input values
            e.target.value = '';
            if (!duplicateValue) {
              chipValues.push(item);
            }
            formik.setFieldValue('emails', chipValues);
            if (chipValues) setEnableSubmit(true);
            else setEnableSubmit(false);
          });
        } else {
          setExceedMaxLimit(true);
        }
      }
    } catch (err) {}
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        contentClassName="modal-b"
        size="lg"
        centered
        backdrop="static"
      >
        {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}
        <Modal.Header closeButton>
          <span className="location-form-title">Send Onboard Invite </span>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            {inValidEmail ? (
              <div className="p-fluid chips-block">
                {submittedEmails === 'Partial' ? (
                  <label className="chip-label" htmlFor="emails">
                    Email invites successfully sent out to the specified Users
                    except for the ones listed out below
                  </label>
                ) : submittedEmails === 'None' ? (
                  <label className="chip-label" htmlFor="emails">
                    Email invites could not be sent out to the specified users
                    due to reasons listed below
                  </label>
                ) : null}
                <AgGridComponent
                  config={defaultConfig}
                  defaultColumnDef={defaultColdef}
                  data={errorData}
                  columns={sourceColumns}
                  gridStyle={sourceUsersGridStyle}
                />
              </div>
            ) : (
              <div className="p-fluid chips-block">
                <label className="chip-label" htmlFor="emails">
                  Please add users below to share the registration invite to CI
                  Subscription manager
                </label>

                <Chips
                  placeholder="Add multiple user emails separated by comma, space or tab"
                  onKeyDown={onChipKeyDown}
                  id="emails"
                  name="emails"
                  max={userEmailLimit}
                  className={formik.errors['emails'] ? 'invalid-chip' : ''}
                  value={formik.values.emails}
                  onChange={(e) => {
                    // code for duplicate domains
                    const emailSet = new Set();
                    e.value.map((x) => emailSet.add(x));
                    formik.handleChange(e);
                    if (emailSet.size == 0) {
                      setEnableSubmit(false);
                    }
                  }}
                  pt={{
                    input: { autoComplete: 'off' },
                  }}
                />
              </div>
            )}
            <Row className="mt-2">
              <Col className="text-start error-text">
                {!inValidEmail && <div>Max limit - {userEmailLimit}</div>}
                {exceedMaxLimit && (
                  <small className="p-error">Exceeded Limit</small>
                )}
              </Col>
              <Col className="text-end">
                {!inValidEmail && (
                  <>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-dark btn-opacity me-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={!enableSubmit}
                      className="btn btn-dark btn-opacity"
                    >
                      Submit
                    </button>
                  </>
                )}
                {inValidEmail && (
                  <button
                    className="btn btn-dark btn-opacity"
                    type="button"
                    onClick={handleClose}
                  >
                    Ok
                  </button>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUserRegistration;
