import React, { memo, useEffect, useState } from 'react';
import store from '../../redux/store';
import { Endpoints, ReduxAction } from '../../constants';
import httpService from '../../services/http-service';
import { useDispatch } from 'react-redux';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';

const StatusDropdown = memo(
  ({ callBackStatus, reset }) => {
    // Accept reset prop
    const [dropDownLoader, setDropDownLoader] = useState(true);
    const [entitlementStatusCollection, setEntitlementStatusCollection] =
      useState([]);
    const dispatch = useDispatch();

    const getEntitlementStatusData = async () => {
      try {
        const storeData = store.getState();
        const storeEntitlementStatusDetails =
          storeData.entitlementStatusDetails;
        if (
          storeEntitlementStatusDetails?.entitlementStatusCollection?.length > 0
        ) {
          let entStatus =
            storeEntitlementStatusDetails.entitlementStatusCollection.map(
              (el) => ({
                ...el,
                checked: false,
              }),
            );
          setEntitlementStatusCollection(entStatus);
          setDropDownLoader(false);
          return;
        }

        const queryParams = { keyItem: 'SFS_ENTITLEMENT_STATUS' };
        const res = await httpService.get(
          Endpoints.configurationApi,
          queryParams,
        );
        const response = res?.data?.results || [];
        const entitlementStatusDetails = response.reduce((acc, item) => {
          if (item.keyItem === 'SFS_ENTITLEMENT_STATUS') {
            const entStatus = JSON.parse(item.keyValue).map((el, i) => ({
              id: i,
              value: el,
              label: el,
              checked: false,
            }));
            acc.entitlementStatusCollection = entStatus;
          }
          return acc;
        }, {});

        dispatch({
          type: ReduxAction.entitlementStatus,
          payload: entitlementStatusDetails,
        });

        setEntitlementStatusCollection(
          entitlementStatusDetails.entitlementStatusCollection,
        );
      } catch (err) {
        setEntitlementStatusCollection([]);
      } finally {
        setDropDownLoader(false);
      }
    };

    useEffect(() => {
      getEntitlementStatusData();
    }, []);

    // Reset selections when reset prop changes
    useEffect(() => {
      setEntitlementStatusCollection((prevCollection) =>
        prevCollection.map((status) => ({ ...status, checked: false })),
      );
      callBackStatus([]); // Inform parent about the reset
    }, [reset, callBackStatus]);

    useEffect(() => {
      const updatedState = entitlementStatusCollection
        ?.filter((statusData) => statusData.checked === true)
        ?.map((filterStatusData) => {
          return {
            statusName: filterStatusData.label,
            statusId: filterStatusData.id,
            statusValue: filterStatusData.value,
          };
        });
      callBackStatus(updatedState);
    }, [entitlementStatusCollection, callBackStatus]);

    const count = entitlementStatusCollection
      ? entitlementStatusCollection.reduce(
          (counter, obj) => (obj?.checked === true ? counter + 1 : counter),
          0,
        )
      : 0;
    return (
      <MultiSelectDropdown
        key={`multi-select-${reset}`} // Add key to force remount on reset
        enableSelectAll={entitlementStatusCollection?.length > 0}
        labelKeyName="label"
        ddOptionsKey="id"
        dropDownLoader={dropDownLoader}
        ddOptions={entitlementStatusCollection}
        setddOptions={setEntitlementStatusCollection}
        ddName={'status'}
        ddPlaceHolder={
          <>
            Status
            <span className={count === 0 ? 'd-none' : 'd-inline'}>
              ({count})
            </span>
          </>
        }
      />
    );
  },
  (op, np) =>
    op?.callBackStatus === np?.callBackStatus && op?.reset === np?.reset,
);

export default StatusDropdown;
