import React, { memo, useEffect, useState } from 'react';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import { Endpoints, maxPageSize } from '../../constants';
import httpService from '../../services/http-service';

const GroupsDropdown = memo(({ contracts, callBackGroups }) => {
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [groupName, setGroupName] = useState();

  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      getGroups(contracts);
    }, 800);
    return () => clearTimeout(reduceCallback);
  }, [contracts]);

  const getDuplicateGroupsWithContractNum = (groups) => {
    let uniqueGroups = [];
    let duplicateGroups = [];
    let tempGroupsWithContractNum = groups?.filter((el) => {
      if (!uniqueGroups?.includes(el.groupName)) {
        uniqueGroups?.push(el.groupName);

        return el;
      } else {
        duplicateGroups?.push(el.groupName);
      }
    });

    tempGroupsWithContractNum = groups?.filter((el) => {
      if (duplicateGroups?.includes(el.groupName)) {
        el.groupName = el.groupName + ' (' + el.contractNumber + ')';
      }
      return el;
    });
    return tempGroupsWithContractNum;
  };

  const getGroups = () => {
    if (contracts?.length > 0 && contracts.some((el) => el.checked)) {
      let queryGroupParams = {
        PageSize: maxPageSize,
        id: new Date().getTime(),
        filter: `contractNumber in(${contracts
          .filter((x) => x.checked)
          .map((t) => (t.id ? '"' + t.id + '"' : ''))
          .join(',')})`,
      };
      httpService
        .get(Endpoints.userGroupApi, queryGroupParams)
        .then((res) => {
          if (res) {
            let groupData = getDuplicateGroupsWithContractNum(
              res.data?.results,
            );
            let finalGroupData = groupData?.map((el, i) => {
              return {
                id: i,
                label: el.groupName,
                checked: false,
                ...el,
              };
            });
            setGroupName(finalGroupData);
          } else setGroupName([]);
        })
        .catch((err) => {
          setGroupName([]);
        })
        .finally(() => {
          setDropDownLoader(false);
        });
    } else setGroupName([]);
  };
  useEffect(() => {
    const updatedState = groupName
      ?.filter((groupsData) => groupsData.checked === true)
      ?.map((filterusersData) => {
        return {
          groupName: filterusersData.groupName,
          groupId: filterusersData.groupId,
          contractNumber: filterusersData.contractNumber,
        };
      });
    callBackGroups(updatedState);
  }, [groupName]);

  const count =
    groupName?.reduce(
      (counter, obj) => (obj?.checked === true ? (counter += 1) : counter),
      0,
    ) ?? 0;

  return (
    <MultiSelectDropdown
      enableSelectAll={groupName?.length > 0}
      searchKeys={['groupName']}
      enableSearch={true}
      labelKeyName="label"
      ddOptionsKey="id"
      dropDownLoader={dropDownLoader}
      ddOptions={groupName}
      setddOptions={setGroupName}
      ddName={'groups'}
      ddPlaceHolder={
        <>
          Groups
          <span className={count === 0 ? 'd-none' : 'd-inline'}>({count})</span>
        </>
      }
    />
  );
});

export default GroupsDropdown;
