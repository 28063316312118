export async function hashText(text) {
  const encoder = new TextEncoder();
  const data = await encoder.encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = await Array.from(new Uint8Array(hashBuffer));
  let hashHex = await hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

export async function verifyHash(text, textHash) {
  let newTextHash = await hashText(text);
  let isVerified = newTextHash === textHash;
  return isVerified;
}
