import React, { useState, useEffect } from 'react';
import {
  Container,
  ListGroup,
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavLink } from 'reactstrap';

import NavbarUser from './NavbarUser';

import logo from './../../assets/img/logo/logo.svg';
import LogoutButton from '../../pages/auth/LogoutButton';
import store from '../../redux/store';
import { useSelector } from 'react-redux';
import { UrlPath } from '../../constants';

const NavbarComponent = () => {
  const location = useLocation();
  const [menu, setMenu] = useState([]);
  const [userName, setUserName] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo;
    if (
      storeUserInfo &&
      storeUserInfo.userInfo &&
      userName !==
        storeUserInfo.userInfo.FirstName + ' ' + storeUserInfo.userInfo.LastName
    ) {
      setUserName(
        storeUserInfo.userInfo.FirstName +
          ' ' +
          storeUserInfo.userInfo.LastName,
      );
    }
  }, [useSelector((x) => x.userInfo)]);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    setMenu([
      {
        name: 'Users & Locations',
        key: 1,
        keyName: '',
        to: '/user-location',
        tag: Link,
        path: ['/user-location', '/export-users'],
      },
      {
        name: 'My Groups',
        key: 2,
        keyName: '',
        to: '/user-group',
        tag: Link,
        path: ['/user-group'],
      },
      {
        name: 'Entitlements',
        key: 3,
        keyName: '',
        to: '/entitlements',
        tag: Link,
        path: [
          '/entitlements',
          '/add-entitlements',
          '/export-entitlements',
          '/product-entitlements',
        ],
      },
      {
        name: 'Packages & Delivery',
        key: 4,
        keyName: '',
        to: '/packages',
        tag: Link,
        path: [
          '/packages',
          '/packages/package-details',
          '/packages/delivery-platform-details',
        ],
      },
      {
        name: userName,
        key: 5,
        keyName: 'username',
        to: '/user-dashboard',
        tag: Link,
        path: ['/user-dashboard'],
      },
    ]);
  }, [userName]);

  const onChangeHistoryClick = () => {
    navigate(UrlPath.changeHistory);
  };

  return (
    <>
      <Navbar variant="light" expand="lg" className="navbar-bg" sticky="top">
        <Container fluid className="mx-lg-1">
          <div className="logo-section">
            <a className="navbar-brand" href="/packages">
              <img src={logo} className="img-fluid me-1 logo-img" alt="Admin" />
            </a>
            <span className="logo-sub-heading">Subscription Manager</span>
          </div>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {menu.map((m) => (
                <li className="nav-item" key={m.key}>
                  {m.keyName !== 'username' ? (
                    <NavLink
                      tag={m.tag}
                      key={m.key}
                      className={
                        m.path.some((p) => location.pathname.includes(p))
                          ? 'active'
                          : ''
                      }
                      to={m.to}
                    >
                      {m.name}
                    </NavLink>
                  ) : (
                    <div
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <NavbarUser
                        name={m.name}
                        navLinkData={{
                          tag: Link,
                          key: m.key,
                          className: m.path.includes(location.pathname)
                            ? 'active'
                            : '',
                          to: m.to,
                        }}
                      />
                      {isHovering && (
                        <>
                          <div className="position-absolute">
                            <ListGroup className="position-fixed option-list">
                              <ListGroup.Item className="option-list-item">
                                <div
                                  id="history_option"
                                  className="option-list-item-text"
                                  onClick={onChangeHistoryClick}
                                >
                                  Change History
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="option-list-item">
                                <LogoutButton />
                              </ListGroup.Item>
                            </ListGroup>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarComponent;
