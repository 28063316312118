import React, { memo, useMemo, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { maxExportSizeLimit, Verbiage, Endpoints } from '../../../constants';
import { ReactComponent as PreviewIcon } from './../../../assets/icons/common/view.svg';
import { ReactComponent as ExportIcon } from './../../../assets/icons/common/export.svg';
import { debounce } from '../../../helpers/debounce';
import { Notify } from '../../../sharedcomponents/Alert/Notify';
import AccountsDropdown from '../AccountsDropdown';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import UsersDropdown from '../../Entitlements/UsersDropdown';
import LocationsDropdown from '../../Entitlements/LocationsDropdown';
import GroupsDropdown from '../GroupsDropdown';
import CheckedIcon from './../../../assets/icons/common/checked.png';
// import ExportServerSide from '../../../sharedcomponents/Export-Server-Side/ExportServerSide';
import {
  getFilterColumns,
  getGroupByColumns,
  getSortColumns,
} from '../../../sharedcomponents/ag-grid/ag-grid-helper';
import httpService from '../../../services/http-service';
import './export-users.scss';
import FileSaver from 'file-saver';

const ExportUsers = memo(
  ({ contracts }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [updatedUsers, setUpdatedUsers] = useState([]);
    const [updatedAccounts, setUpdatedAccounts] = useState([]);
    const [updatedLocations, setUpdatedLocations] = useState([]);
    const [updatedGroups, setUpdatedGroups] = useState([]);
    const [packageDistributorData, setPackageDistributorData] = useState([]);
    const [enablePreview, setEnablePreview] = useState(false);
    const [displayCount, setDisplayCount] = useState(false);
    const [isGridDataAvailable, setIsGridDataAvailable] = useState(false);
    const [exportCount, setExportCount] = useState('0');
    const [isExportInprogress, setIsExportInprogress] = useState(false);

    const callBackUsers = (props) => setUpdatedUsers(props);
    const callBackAccounts = (props) => setUpdatedAccounts(props);
    const callBackLocations = (props) => setUpdatedLocations(props);
    const callBackGroups = (props) => setUpdatedGroups(props);

    const resetSearchFilter = () => {
      setIsGridDataAvailable(false);
      setDisplayCount(false);
      setUpdatedUsers([]);
      setUpdatedAccounts([]);
      setUpdatedLocations([]);
      setUpdatedGroups([]);
      setExportCount([]);
    };

    const defaultConfig = {
      editable: true,
      isExportCSV: false,
      suppressDragLeaveHidesColumns: true,
      isExportExcel: false,
      pivotPanelShow: '',
      pagination: false,
      isAutoSizeColums: true,
      enableCharts: false,
      rowGroupPanelShow: false,
      sideBar: true,
      overlayNoRowsTemplate:
        contracts && contracts.some((x) => x.checked)
          ? Verbiage.users
          : Verbiage.noData,
    };
    const gridStyle = { height: '83vh' };
    const columnFilterParams = {
      filter: 'agTextColumnFilter',
      enableRowGroup: false,
      enablePivot: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    };

    const defaultColdef = {
      ...columnFilterParams,
      sortable: true,
      resizable: true,
    };

    const columns = [
      {
        headerName: '',
        field: 'contractNumber',
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: [
            'inRange',
            {
              displayKey: 'inRange',
              displayName: 'Range Between',
              predicate: ([filterValue], cellValue) => {
                return cellValue == null || cellValue < filterValue;
              },
            },
          ],
        },
        suppressHeaderMenuButton: true,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        headerName: '',
        field: 'officeLocationId',
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: [
            'inRange',
            {
              displayKey: 'inRange',
              displayName: 'Range Between',
              predicate: ([filterValue], cellValue) => {
                return cellValue == null || cellValue < filterValue;
              },
            },
          ],
        },
        suppressHeaderMenuButton: true,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        headerName: 'Email ID',
        headerTooltip: 'Email ID',
        field: 'email',
        initialFlex: 3,
        lockPosition: true,
        filterParams: {
          filterOptions: [
            'inRange',
            {
              displayKey: 'inRange',
              displayName: 'Range Between',
              predicate: ([filterValue], cellValue) => {
                return cellValue == null || cellValue < filterValue;
              },
            },
          ],
        },
      },
      {
        headerName: 'Name',
        headerTooltip: 'Name',
        field: 'contactName',
        tooltipField: 'contactName',
        initialFlex: 3,
      },
      {
        headerName: 'Account',
        headerTooltip: 'Account',
        field: 'account',
        tooltipField: 'account',
        initialFlex: 4,
        filterParams: {
          filterOptions: [
            'inRange',
            {
              displayKey: 'inRange',
              displayName: 'Range Between',
              predicate: ([filterValue], cellValue) => {
                return cellValue == null || cellValue < filterValue;
              },
            },
          ],
        },
      },
      {
        headerName: 'Location',
        headerTooltip: 'Location',
        field: 'officeLocation',
        tooltipField: 'officeLocation',
        initialFlex: 5,
        filterParams: {
          filterOptions: [
            'inRange',
            {
              displayKey: 'inRange',
              displayName: 'Range Between',
              predicate: ([filterValue], cellValue) => {
                return cellValue == null || cellValue < filterValue;
              },
            },
          ],
        },
      },
      {
        headerName: 'Job Function',
        headerTooltip: 'Job Function',
        field: 'jobFunction',
        tooltipField: 'jobFunction',
        initialFlex: 3,
      },
      {
        headerName: 'Contract ID',
        headerTooltip: 'Contract ID',
        field: 'contractNumber',
        tooltipValueGetter: (params) =>
          params.value +
          (params.data.contractDescription
            ? ' - ' + params.data.contractDescription
            : ''),
        initialFlex: 3,
        cellRenderer: (params) => {
          return (
            params.value +
            (params.data.contractDescription
              ? ' - ' + params.data.contractDescription
              : '')
          );
        },
      },
      {
        headerName: 'Group Name',
        headerTooltip: 'Group Name',
        field: 'groupName',
        tooltipField: 'groupName',
        initialFlex: 3,
        filterParams: {
          filterOptions: [
            'inRange',
            {
              displayKey: 'inRange',
              displayName: 'Range Between',
              predicate: ([filterValue], cellValue) => {
                return cellValue == null || cellValue < filterValue;
              },
            },
          ],
        },
      },
      {
        headerName: 'Entitled',
        headerTooltip: 'Entitled',
        field: 'entitled',
        tooltipField: 'entitled',
        initialFlex: 2,
        cellRenderer: ({ data }) => {
          if (data?.entitled === 'Yes') {
            return <img src={CheckedIcon} className="width-20" />;
          } else return '';
        },
      },
    ];
    const gridRef = useRef();
    const onGridReadyHandler = (params) => {
      gridRef.current = params;
      gridRef.current.api.setGridOption(
        'serverSideDatasource',
        getServerSideDatasource(),
      );
    };

    const getServerSideDatasource = () => {
      return {
        getRows: (params) => {
          try {
            setExportCount('0');
            let AgParams = params?.request;
            let contractFilterModel =
              AgParams?.filterModel['contractNumber']?.filter;

            if (
              contractFilterModel?.length > 0 &&
              contractFilterModel !== 'NoContractSelected'
            ) {
              let FilterColumns =
                'email,contactName,contractNumber,account,officeLocation,jobFunction,entitled,groupName,emailProcessed,department,designation';
              let searchText = document.getElementById(
                'user-search-filter-input',
              )?.value;
              let queryParams = {
                Filter: getFilterColumns(AgParams),
                FilteredColumns: JSON.stringify(
                  gridRef.current.api.getFilterModel(),
                ),
                Field:
                  'contactName,contactId,groupid,groupName,contractNumber,contractDescription,email,phone,department,jobFunction,designation,isEmailOptOut,emailProcessed,account,accountId,officeLocation,officeLocationId,entitled,modifiedDate',
                Sort: getSortColumns(
                  AgParams,
                  'contactName:asc,officeLocation:asc',
                ),
                PageSize: AgParams.endRow - AgParams.startRow,
                Page:
                  AgParams.startRow / (AgParams.endRow - AgParams.startRow) + 1,
                groupBy: getGroupByColumns(AgParams),
                querySearch: searchText,
                searchColumns: searchText ? FilterColumns : '',
                id: String(Date.now()),
              };
              gridRef.current.api.showLoadingOverlay();
              httpService
                .post(Endpoints.userReportApi, queryParams)
                .then(({ data }) => {
                  if (queryParams.Filter.includes('AND')) {
                    setExportCount(data?.metadata?.count?.toLocaleString());
                  }
                  let rowData = data?.results ?? data?.aggResultValue;
                  // new key created for tooltip
                  rowData = rowData.map((el) => {
                    el.isEmailOptOutTooltip =
                      el.isEmailOptOut !== 1 ? 'Yes' : 'No';
                    return { ...el };
                  });

                  params.success({
                    rowData: rowData,
                    rowCount: data?.metadata.count,
                  });

                  if (rowData?.length === 0)
                    gridRef.current.api.showNoRowsOverlay();
                  else {
                    gridRef.current.api.hideOverlay();
                  }
                })
                .catch(() => {
                  params.fail();
                  gridRef.current.api.hideOverlay();
                });
            } else {
              params.success({
                rowData: [],
                rowCount: 0,
              });

              if (contractFilterModel === 'NoContractSelected') {
                gridRef.current.api.showNoRowsOverlay();
              }
            }
          } catch (err) {
            params.fail();
          }
        },
      };
    };
    const getRowId = (params) => {
      if (params?.data && params.data.contactId && params.data.contractNumber) {
        return (
          'user-' + params.data.contactId + '-' + params.data.contractNumber
        );
      }
    };

    useEffect(() => {
      const reduceCallback = setTimeout(() => {
        contractChangeHandler();
      }, 1000);
      return () => clearTimeout(reduceCallback);
    }, [contracts]);

    useEffect(() => {
      const hasSelectedItems =
        (updatedUsers && updatedUsers.length > 0) ||
        (updatedAccounts && updatedAccounts.length > 0) ||
        (updatedLocations && updatedLocations.length > 0) ||
        (updatedGroups && updatedGroups.length > 0);
      if (hasSelectedItems) {
        setEnablePreview(true);
        setButtonIcons({ ExportIcon: true });
      } else {
        setEnablePreview(false);
      }
    }, [updatedUsers, updatedAccounts, updatedLocations, updatedGroups]);

    const contractChangeHandler = () => {
      resetSearchFilter();
      if (gridRef.current && contracts && contracts.length > 0) {
        let filteredContracts = contracts.filter((x) => x.checked);
        let filterValue = {
          filter:
            filteredContracts.length !== 0
              ? filteredContracts
                  .map((t) =>
                    t.contractNumber ? '"' + t.contractNumber + '"' : '',
                  )
                  .join(',')
              : 'NoContractSelected',
          filterType: 'text',
          type: 'inRange',
        };

        gridRef.current.api.setFilterModel(
          getAllFilterModel('contractNumber', filterValue),
        );
      } else {
        gridRef.current.api.showNoRowsOverlay();
      }
    };

    const getAllFilterModel = (colId, newModel) => {
      let currentModel = gridRef.current?.api?.getFilterModel();
      currentModel[colId] = newModel;
      return currentModel;
    };

    const clickPreview = () => {
      // update grid filter
      if (enablePreview && gridRef.current) {
        setIsGridDataAvailable(true);
        setDisplayCount(true);
        getFilterParameters();
      }
    };

    const [buttonIcons, setButtonIcons] = useState({
      ExportIcon: false,
    });

    const getDate = () => {
      let currentDate = new Date();
      return (
        currentDate.getDate() +
        '-' +
        (currentDate.getMonth() + 1) +
        '-' +
        currentDate.getFullYear()
      );
    };

    const getFilterParameters = () => {
      if (updatedUsers && updatedUsers.length > 0) {
        let filterValue = {
          filter: updatedUsers
            .map((t) => (t.emailId ? '"' + t.emailId + '"' : ''))
            .join(','),
          filterType: 'text',
          type: 'inRange',
        };

        gridRef.current.api.setFilterModel(
          getAllFilterModel('email', filterValue),
        );
      } else {
        gridRef.current.api.setFilterModel(getAllFilterModel('email', {}));
      }
      if (updatedAccounts && updatedAccounts.length > 0) {
        let filterValue = {
          filter: updatedAccounts
            .map((t) => (t.accountName ? '"' + t.accountName + '"' : ''))
            .join(','),
          filterType: 'text',
          type: 'inRange',
        };

        gridRef.current.api.setFilterModel(
          getAllFilterModel('account', filterValue),
        );
      } else {
        gridRef.current.api.setFilterModel(getAllFilterModel('account', {}));
      }
      if (updatedLocations && updatedLocations.length > 0) {
        let filterValue = {
          filter: updatedLocations
            .map((t) =>
              t.officeLocationId ? '"' + t.officeLocationId + '"' : '',
            )
            .join(','),
          filterType: 'text',
          type: 'inRange',
        };

        gridRef.current.api.setFilterModel(
          getAllFilterModel('officeLocationId', filterValue),
        );
      } else {
        gridRef.current.api.setFilterModel(
          getAllFilterModel('officeLocationId', {}),
        );
      }
      if (updatedGroups && updatedGroups.length > 0) {
        let filterValue = {
          filter: updatedGroups
            .map((t) => (t.groupName ? '"' + t.groupName + '"' : ''))
            .join(','),
          filterType: 'text',
          type: 'inRange',
        };

        gridRef.current.api.setFilterModel(
          getAllFilterModel('groupName', filterValue),
        );
      } else {
        gridRef.current.api.setFilterModel(getAllFilterModel('groupName', {}));
      }
    };

    const getUsersQueryParams = async () => {
      try {
        if (gridRef) {
          getFilterParameters();
          const AgParams = gridRef.current?.api;
          const currentFilterModel = {
            filterModel: AgParams?.getFilterModel(),
          };
          let queryParams = {
            Page: 1,
            PageSize: maxExportSizeLimit,
            Filter: await getFilterColumns(currentFilterModel),
            FilteredColumns: await JSON.stringify(
              currentFilterModel.filterModel,
            ),
            Field:
              'contactName,contactId,groupid,groupName,contractNumber,email,phone,department,jobFunction,designation,isEmailOptOut,emailProcessed,account,accountId,officeLocation,officeLocationId,entitled,modifiedDate',
            Sort: await getSortColumns(
              AgParams,
              'contactName:asc,officeLocation:asc',
            ),
          };

          return queryParams;
        }
      } catch (error) {}
    };

    const exportUserCSV = async () => {
      if (!isExportInprogress && buttonIcons.ExportIcon) {
        try {
          setExportCount('0');
          setIsExportInprogress(true);
          setDisplayCount(true);
          Notify({
            alert: true,
            type: '',
            title: 'Your download is in progress',
          });
          var queryParams = await getUsersQueryParams();
          await httpService
            .post(
              Endpoints.userExportApi,
              queryParams,
              {},
              {
                responseType: 'blob',
              },
            )
            .then(async (response) => {
              const totalCount = response.headers['total-count'];
              if (totalCount < maxExportSizeLimit) {
                setExportCount(totalCount?.toLocaleString());
                FileSaver.saveAs(response.data, 'Users' + getDate());
                Notify({
                  alert: true,
                  type: 'success',
                  title: 'Users Exported Successfully',
                });
              } else {
                Notify({
                  alert: true,
                  type: 'error',
                  dialogClassName: 'alert-export-success-max-width',
                  title:
                    'Number of records for export ' +
                    totalCount +
                    ' are greater than the allowed limit (' +
                    maxExportSizeLimit +
                    '). Please adjust the export criteria to ensure that the number of records  for export are within allowed limit.',
                });
              }
            })
            .catch((error) => {
              Notify({
                alert: true,
                type: 'error',
                title: 'Something went wrong',
              });
            })
            .finally(() => {
              setIsExportInprogress(false);
            });
        } catch (error) {
          setIsExportInprogress(false);
          console.log('Error in ExportServerSide', error);
        }
      }
    };

    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              <div className="add-entitlements left-0 mt-2"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <UsersDropdown
                contracts={contracts}
                callBackUsers={callBackUsers}
                isGroupFilterApply={false}
              />
            </Col>
            <Col>
              <AccountsDropdown
                contracts={contracts}
                callBackAccounts={callBackAccounts}
              />
            </Col>
            <Col>
              <LocationsDropdown
                contracts={contracts}
                callBackLocations={callBackLocations}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <GroupsDropdown
                contracts={contracts}
                callBackGroups={callBackGroups}
              />
            </Col>
            <Col></Col>
            <Col className="d-flex justify-content-end">
              <button
                className="btn btn-dark btn-opacity me-2"
                disabled={!enablePreview}
                onClick={clickPreview}
              >
                Preview
              </button>
              <button
                className="btn btn-dark btn-opacity"
                disabled={!enablePreview}
                onClick={exportUserCSV}
              >
                Export
              </button>
            </Col>
          </Row>
          <Row className="mt-2 mb-n3">
            <div className="mb-2">
              Number of records for Export:{' '}
              {isGridDataAvailable || displayCount ? exportCount : '0'}
            </div>
          </Row>
          <Row>
            <Col>
              <div className="ag-grid-export-users-table">
                <div
                  className={
                    isGridDataAvailable ? 'd-none' : 'd-block text-center pt-5'
                  }
                >
                  <p>Please select criteria above to preview and export.</p>
                  <p>
                    Maximum of {maxExportSizeLimit} records can be downloaded at
                    once. Please update your criteria to ensure Number of
                    records selected for export is less than the limit.
                  </p>
                </div>
                <div className={isGridDataAvailable ? 'd-block ' : 'd-none'}>
                  <div className="ag-grid-table with-multi-tabs mbx-12 export-user-grid">
                    <AgGridComponent
                      config={defaultConfig}
                      gridStyle={gridStyle}
                      defaultColumnDef={defaultColdef}
                      getRowId={getRowId}
                      columns={columns}
                      onGridReady={onGridReadyHandler}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="notes-text-label mt-1" id="notes-label">
          <span className="notes-asterisk-label">*</span>Note that maximum of{' '}
          {maxExportSizeLimit} records can be downloaded at once. Please update
          your criteria to ensure Number of records selected for export is less
          than the limit.
        </div>
      </>
    );
  },
  (op, np) => op?.contracts === np?.contracts,
);

export default ExportUsers;
