import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const TokenRefreshWrapper = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const minutes = 60 * 1000;

  useEffect(() => {
    let refreshInterval;

    const setupAutomaticTokenRefresh = async () => {
      if (!authState?.isAuthenticated) {
        // User not authenticated, no need to refresh token
        return;
      }

      const tokenManager = oktaAuth.tokenManager;
      const accessToken = await tokenManager.get('accessToken');

      // Calculate the time to refresh the token before it expires (e.g., 5 minutes before expiry)
      const refreshMinutesBeforeExpiry = 10 * minutes; // IMP: change this value as needed
      const expiresAt = accessToken.expiresAt * 1000; // Convert to milliseconds
      const refreshTime = expiresAt - Date.now() - refreshMinutesBeforeExpiry;

      if (refreshTime > 0) {
        // Set up a timer to refresh the token
        refreshInterval = setTimeout(() => {
          oktaAuth.tokenManager
            .renew('accessToken')
            .then((newToken) => {
              // Set the new token in local Storage
              const loginTime = new Date().getTime();
              let accessToken = newToken?.accessToken;
              if (accessToken) {
                localStorage.setItem('token', accessToken);
                localStorage.setItem('expiresAt', newToken?.expiresAt);
              }
              localStorage.setItem('lastLoginTime', loginTime);
            })
            .catch((err) => {
              signOut();
            });
        }, refreshTime);
      }
    };

    setupAutomaticTokenRefresh();

    return () => {
      if (refreshInterval) {
        clearTimeout(refreshInterval);
      }
    };
  }, [oktaAuth, authState]);

  const signOut = () => {
    localStorage.clear();
    oktaAuth.signOut();
  };

  return <>{children}</>;
};

export default TokenRefreshWrapper;
