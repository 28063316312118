import * as React from 'react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../Loader';
import { useOktaAuth } from '@okta/okta-react';
import { setTitle } from './../../helpers/set-page-title';

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, roles, layout: Layout, element }) {
  const [isLoading, setLoading] = useState(true);
  const { oktaAuth } = useOktaAuth();
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const userRoles = userInfo?.SPGGroups ?? [];
  const hasRequiredRoles = roles.some((role) => userRoles.includes(role));

  if (
    window.location.pathname !== localStorage.getItem('currentRoute') &&
    localStorage.getItem('currentRoute') !==
      localStorage.getItem('previousRoute')
  ) {
    localStorage.setItem('previousRoute', localStorage.getItem('currentRoute'));
  }

  // track user's last page to return them directly if they refresh
  localStorage.setItem('currentRoute', window.location.pathname);
  setTitle(window.location.pathname);

  useEffect(() => {
    setLoading(false);
  }, []);

  const signOut = () => {
    localStorage.clear();
    oktaAuth.signOut();
  };

  const lastLoginTime = getLastLoginTime();
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - lastLoginTime;
  const initialLoginTimeDiff = currentTime - getInitialLoginTime();
  const totalHoursPassed = Math.floor(initialLoginTimeDiff / (1000 * 60 * 60));
  const hoursPassed = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutesPassed = Math.floor(timeDifference / (1000 * 60));
  // refresh or log out user based on time elapsed since login
  if (hoursPassed >= 1 && totalHoursPassed < 24) {
    oktaAuth.token
      .renewTokens()
      .then((newToken) => {
        // token refresh successful
        const loginTime = new Date().getTime();
        let accessToken = newToken?.accessToken?.accessToken;
        if (accessToken) {
          localStorage.setItem('token', accessToken);
          localStorage.setItem(
            'refreshToken',
            newToken?.refreshToken?.refreshToken,
          );
          localStorage.setItem('expiresAt', newToken?.idToken?.expiresAt);
        }
        localStorage.setItem('lastLoginTime', loginTime);
      })
      .catch((error) => {
        // token renewal error
        signOut();
      });
  } else if (totalHoursPassed >= 24) {
    // sign user out due to inactivity
    signOut();
  }

  useEffect(() => {
    if (
      oktaAuth.authStateManager._authState == null ||
      !oktaAuth.authStateManager._authState.isAuthenticated
    ) {
      return <Navigate to="/" />;
    }
  }, [oktaAuth]);

  if (isLoading) {
    return hasRequiredRoles ? (
      <>
        Loading...
        <Loader />
      </>
    ) : (
      <Navigate to="/" />
    );
  } else {
    return hasRequiredRoles ? <Layout>{children}</Layout> : <Navigate to="/" />;
  }
}

function getLastLoginTime() {
  const lastLoginTime = localStorage.getItem('lastLoginTime');
  return lastLoginTime ? parseInt(lastLoginTime) : null;
}
function getInitialLoginTime() {
  const initialLoginTime = localStorage.getItem('initialLoginTime');
  return initialLoginTime ? parseInt(initialLoginTime) : null;
}

export default AuthGuard;
