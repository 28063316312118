import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Verbiage } from '../../constants';
import { debounce } from '../../helpers/debounce';
import { store } from '../../redux/store';
import httpService from '../../services/http-service';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import {
  getFilterColumns,
  getGroupByColumns,
  getSortColumns,
} from '../../sharedcomponents/ag-grid/ag-grid-helper';
import { ReactComponent as AddLocationIcon } from './../../assets/icons/add-pin.svg';
import { ReactComponent as DeleteIcon } from './../../assets/icons/delete.svg';
import Loader from './../../components/Loader';
import {
  addressValidation,
  Endpoints,
  GenericError,
  loaderStyle,
} from './../../constants';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import AddressVerificationPopup from './address-verification-popup';
import DuplicateAddressPopup from './duplicate-address-popup';
import LocationAddEdit from './location-add-edit';
import './locations.scss';

const Locations = ({ contracts }) => {
  const { valid, inValid, partial } = addressValidation.status;
  const defaultConfig = {
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    suppressDragLeaveHidesColumns: true,
    pagination: false,
    isAutoSizeColums: true,
    enableCharts: false,
    paginationPageSize: 50,
    rowGroupPanelShow: false,
    sideBar: true,
    overlayNoRowsTemplate:
      contracts && contracts.some((x) => x.checked)
        ? Verbiage.locations
        : Verbiage.noData,
  };
  const gridRef = useRef();

  const isFirstColumn = (params) => {
    let displayedColumns = params.api?.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };

  const isSameOrFirstColumn = (params) => {
    // Show checkbox on each row of first column and on grouped, show it only on grouped columns
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let rowGroupCheckBox = !params?.node?.group;
    let showCheckBox =
      // rowGroupCheckBox &&
      displayedColumns[params?.node?.level] === params?.column;
    return showCheckBox;
  };

  const [buttonIcons, setButtonIcons] = useState({
    DeleteIcon: false,
  });
  const [loading, setLoading] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addressVerifyModal, setAddressVerifyModal] = useState(false);
  const [duplicateAddressModal, setDuplicateAddressModal] = useState({
    show: false,
    data: null,
    isEdit: false,
    isFromRecommendationClick: false,
  });
  const [duplicateLocations, setDuplicateLocations] = useState();
  const [HCID, setHCID] = useState('');
  const [editedLocation, setEditedLocation] = useState({});
  const [isFirstDataLoaded, setIsFirstDataLoaded] = useState(false);
  const [locationValidationResponse, setLocationValidationResponse] = useState(
    [],
  );
  const [newPostData, setNewPostData] = useState('');
  const [submittedAddress, setSubmittedAddress] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [fromRecommendationClick, setFromRecommendationClick] = useState(false);

  const closeAddLocationModal = () => {
    setShowModal(false);
  };
  const closeAddressVerifyModal = () => {
    setAddressVerifyModal(false);
  };

  const getCountryFullName = (countryIsoCode, address) => {
    try {
      // Get country full name from country ISO code
      let fullName;
      let isoCode = address?.postalAddress?.regionCode ?? countryIsoCode;
      const storeData = store.getState();
      let countryStateList = storeData?.locationTab?.countryStateList;
      if (countryStateList?.length > 0)
        fullName = countryStateList.find((el) => el.code === isoCode)?.country;

      return fullName ?? countryIsoCode;
    } catch (error) {}
  };

  const getStateFullName = (stateIsoCode, address) => {
    try {
      // Get state full name from state ISO code
      let fullName;
      let countryIsoCode = address?.postalAddress?.regionCode;
      const storeData = store.getState();
      let countryStateList = storeData?.locationTab?.countryStateList;
      if (countryIsoCode && countryStateList?.length > 0) {
        let foundCountry = countryStateList.find(
          (el) => el.code === countryIsoCode || el.country === countryIsoCode,
        );
        let stateList = foundCountry?.states ?? [];
        fullName = stateList.find(
          (st) =>
            st?.code === address?.postalAddress?.administrativeArea ||
            st?.code === stateIsoCode,
        )?.state;
      }

      return fullName ?? stateIsoCode;
    } catch (error) {}
  };

  const submitAddressVerifyModal = (userSelection) => {
    if (userSelection == -1) {
      actionClick(newPostData, isEdit ? 'update' : 'save');
    } else if (Number.isInteger(Number(userSelection))) {
      let updatedPostData = {
        ...locationValidationResponse[Number(userSelection)],
      };
      updatedPostData.addressValidationStatus = valid;
      updatedPostData.accountId = newPostData.accountId;
      updatedPostData.contractNumber = newPostData.contractNumber;
      updatedPostData.isDeleted = 0;
      updatedPostData.officeLocationId = newPostData.officeLocationId;
      updatedPostData.officeLocationName = getFullAddress(updatedPostData);
      updatedPostData.userId = newPostData.userId;

      actionClick(updatedPostData, isEdit ? 'update' : 'save');
    }

    setAddressVerifyModal(false);
  };

  const openLocationModal = (locationValue) => {
    setShowModal(true);
    if (locationValue !== undefined || locationValue !== null) {
      let editLocationDetail = { ...locationValue };
      setEditedLocation(editLocationDetail);
    } else setEditedLocation({});
  };

  const customValueGetter = (params, columnId) => {
    // returns custom string
    let newValue = '';
    if (params) {
      let colId = columnId ?? params.column?.colId;
      if (colId === 'addressValidationStatus') {
        switch (params.data?.addressValidationStatus) {
          case valid:
            newValue = addressValidation.msg.valid;
            break;
          case inValid:
            newValue = addressValidation.msg.inValid;
            break;
          case partial:
            newValue = addressValidation.msg.partial;
            break;
          default:
            newValue = '';
        }
      }
    }
    return newValue;
  };

  const columnFilterParams = {
    filter: 'agTextColumnFilter',
    enableRowGroup: false,
    enablePivot: false,
    sortable: true,
    resizable: true,
    lockPosition: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };

  const defaultColdef = {
    ...columnFilterParams,
    checkboxSelection: isSameOrFirstColumn,
    headerCheckboxSelection: isFirstColumn,
  };

  const locationGridColumns = [
    {
      headerName: '',
      field: 'contractNumber',
      resizable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: [
          'inRange',
          {
            displayKey: 'inRange',
            displayName: 'Range Between',
            predicate: ([filterValue], cellValue) => {
              return cellValue == null || cellValue < filterValue;
            },
          },
        ],
      },
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Location Name',
      headerTooltip: 'Location Name',
      field: 'officeLocationName',
      cellRenderer: (params) => {
        if (params.value != null) {
          return (
            <div
              className="email-label"
              title={params.value}
              onClick={() => {
                setEditedLocation(params.data);
                setShowModal(true);
              }}
            >
              {params.value}
            </div>
          );
        } else return '';
      },
      initialFlex: 5,
      ...columnFilterParams,
    },
    {
      headerName: 'Contract ID',
      headerTooltip: 'Contract ID',
      field: 'contractNumber',
      tooltipField: 'contractNumber',
      initialFlex: 2,
      cellRenderer: (params) => {
        return (
          params.value +
          (params.data.contractDescription
            ? ' - ' + params.data.contractDescription
            : '')
        );
      },
      ...columnFilterParams,
    },
  ];

  // Delete location pop code starts here
  const openStatusModal = (title) => {
    Notify({
      alert: true,
      type: 'warning',
      title: title,
      confirmBtnText: 'YES DELETE',
      cancelBtnText: 'NO, KEEP IT',
      onConfirm: () => deleteLocations(),
    });
  };
  const getDeletedTitle = () => {
    let selectedLocations = gridRef.current.api.getSelectedRows();
    if (selectedLocations && selectedLocations.length > 0) {
      switch (true) {
        case selectedLocations.length === 1:
          return 'Are you sure you want to permanently delete the location?';
        case selectedLocations.length > 1:
          return 'Are you sure you want to permanently delete the locations?';
        default:
          return 'Are you sure you want to delete the location?';
      }
    }
    return '';
  };
  // Delete location pop code ends here

  // Delete icon active code starts here
  const deleteClickHandler = () => {
    if (buttonIcons.DeleteIcon) {
      let title = getDeletedTitle();
      openStatusModal(title);
    }
  };
  // Delete icon active code ends here

  const getAllFilterModel = (colId, newModel) => {
    let currentModel = gridRef.current.api.getFilterModel();
    currentModel[colId] = newModel;
    return currentModel;
  };

  const getServerSideDatasource = () => {
    return {
      getRows: (params) => {
        if (
          params.request &&
          params.request.filterModel['contractNumber'] &&
          params.request.filterModel['contractNumber'].filter
        ) {
          setIsFirstDataLoaded(true);
          let queryParams = {
            Filter: getFilterColumns(params.request),
            FilteredColumns: JSON.stringify(
              gridRef.current.api.getFilterModel(),
            ),
            Sort: getSortColumns(params.request, 'modifiedDate:desc'),
            PageSize: params.request.endRow - params.request.startRow,
            Page:
              params.request.startRow /
                (params.request.endRow - params.request.startRow) +
              1,
            field: '',
            id: new Date().getTime(),
            groupBy: getGroupByColumns(params.request),
            querySearch: document.getElementById('location-search-filter-input')
              .value,
            searchColumns: document.getElementById(
              'location-search-filter-input',
            ).value
              ? 'officelocationname,fulladdress,contractNumber'
              : '',
          };
          httpService
            .get(Endpoints.locationApi, queryParams)
            .then((response) => {
              // call the success callback
              params.success({
                rowData: response.data?.results
                  ? response.data?.results
                  : response.data?.aggResultValue,
                rowCount: response.data?.metadata.count,
              });
              if (
                (response.data?.results
                  ? response.data?.results
                  : response.data?.aggResultValue
                )?.length === 0
              )
                gridRef.current.api.showNoRowsOverlay();
              else {
                gridRef.current.api.hideOverlay();
              }
            })
            .catch((err) => {
              params.fail();
            });
        } else {
          params.success({
            rowData: [],
            rowCount: 0,
          });
          gridRef.current.api.showNoRowsOverlay();
          if (isFirstDataLoaded) {
            gridRef.current.api.showNoRowsOverlay();
          } else {
            setIsFirstDataLoaded(true);
          }
        }
      },
    };
  };

  const contractChangeHandler = () => {
    if (gridRef.current && contracts && contracts.length > 0) {
      let filterValue = {
        filter: contracts
          .filter((x) => x.checked)
          .map((t) => (t.contractNumber ? '"' + t.contractNumber + '"' : ''))
          .join(','),
        filterType: 'text',
        type: 'inRange',
      };
      gridRef.current.api.showLoadingOverlay();
      gridRef.current.api.setFilterModel(
        getAllFilterModel('contractNumber', filterValue),
      );
    } else {
      gridRef?.current?.api.showNoRowsOverlay();
    }
  };
  useEffect(() => {
    const reduceCallback = setTimeout(
      () => {
        contractChangeHandler();
      },
      isFirstDataLoaded ? 1000 : 100,
    );
    return () => clearTimeout(reduceCallback);
  }, [contracts]);

  const onFilterTextBoxChanged = useCallback(() => {
    if (
      !document.getElementById('location-search-filter-input').value ||
      document.getElementById('location-search-filter-input').value.length > 2
    ) {
      gridRef.current.api.onFilterChanged();
    }
  }, []);
  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const onGridReadyHandler = (params) => {
    gridRef.current = params;
    gridRef.current.api.showLoadingOverlay();

    gridRef.current.api.setGridOption(
      'serverSideDatasource',
      getServerSideDatasource(),
    );
  };

  const googleValidatorKeyReplacer = (key) => {
    if (key === 'locality') {
      return 'city';
    }
    return key.replace('_', ' ');
  };

  const onInValidAddress = (res, saveAction) => {
    const { results } = res.data;
    let title = `Invalid Location.`;
    if (results && results[0]?.isPlaceApi) {
      if (locationValidationResponse.length == 0) {
        title += ' Entered address does not match with any records';
      }
    } else {
      if (results?.length > 0) {
        title += ` Entered address has missing information`;
        let { unconfirmedComponentTypes, missingComponentTypes } =
          results[0]?.address;
        if (
          unconfirmedComponentTypes?.length > 0 ||
          missingComponentTypes?.length > 0
        ) {
          title += ' :';
        }
        const unconfirmedComponent = unconfirmedComponentTypes
          ?.map((el) => googleValidatorKeyReplacer(el))
          .join(', ');
        const missingComponent = missingComponentTypes
          ?.map((el) => googleValidatorKeyReplacer(el))
          .join(', ');
        if (unconfirmedComponent) {
          title += unconfirmedComponent;
        }

        if (missingComponent) {
          title += `, ${missingComponent}`;
        }
      } else {
        title += ' Entered address does not match with any records';
      }
    }

    Notify({
      alert: true,
      type: 'warning',
      title: title,
      confirmBtnText: 'Continue saving',
      cancelBtnText: 'Cancel',
      onConfirm: () => saveAction(),
    });
  };

  const getFullAddress = (addressObject) => {
    return `${addressObject.address_1 ? addressObject.address_1 + ', ' : ''}${
      addressObject.address_2 ? addressObject.address_2 + ', ' : ''
    }${addressObject.city}, ${addressObject.state} ${
      addressObject.postalCode
    }, ${addressObject.country}`;
  };

  const findValidationStatus = (res, fullAddress) => {
    const granularities = [
      'OTHER',
      'ROUTE',
      'BLOCK',
      'PREMISE_PROXIMITY',
      'PREMISE',
      'SUB_PREMISE',
    ];

    // Returns Valid, InValid, Partial based on address given
    try {
      const results = res.data?.results;
      if (results?.length > 0) {
        const {
          addressComplete,
          hasReplacedComponents,
          hasInferredComponents,
          hasUnconfirmedComponents,
          validationGranularity,
        } = results[0]?.verdict;
        // Address Validation API resp
        if (
          addressComplete &&
          !hasReplacedComponents &&
          !hasInferredComponents &&
          !hasUnconfirmedComponents
        ) {
          // Valid: Address matches
          return valid;
        } else if (
          (!addressComplete &&
            !hasReplacedComponents &&
            !hasInferredComponents) ||
          hasUnconfirmedComponents ||
          validationGranularity == 'OTHER'
        ) {
          // Invalid: Non-Existen Sub-Premises
          return inValid;
        } else if (
          granularities
            .slice(1)
            .some((granularity) =>
              validationGranularity.includes(granularity),
            ) &&
          addressComplete &&
          (hasInferredComponents || hasReplacedComponents)
        ) {
          // Partial: Missing Sub-Premises || Misspelled locality || Missing locality
          return partial;
        }
      }
      return inValid;
    } catch (err) {}
  };
  const findPlaceValidationStatus = (res, fullAddress) => {
    // Returns Valid, InValid, Partial based on address given
    try {
      // New Places API resp
      if (fullAddress === getFullAddress(res)) {
        return valid;
      }
      return partial;
    } catch (err) {}
  };
  const isObjectValidated = (obj) => {
    if (obj.address_1 && obj.city && obj.postalCode && obj.state && obj.country)
      return true;
    return false;
  };

  const updateResponseToLocation = (responseObj) => {
    let updatedResponse = [];
    const fixedComponents = [
      'locality',
      'postal_town',
      'administrative_area_level_1',
      'postal_code',
      'country',
    ];
    if (responseObj?.length > 0) {
      responseObj.forEach((res) => {
        let updatedAddressData = {};

        let localityText = '';
        let subLocalityText = '';

        const localityComponent = res.address.addressComponents.find(
          (component) => component.componentType === 'locality',
        );

        const subLocalityComponent = res.address.addressComponents.find(
          (component) => component.componentType.includes('sublocality'),
        );

        if (localityComponent) {
          localityText = localityComponent.componentName.text;
        }

        if (subLocalityComponent) {
          subLocalityText = subLocalityComponent.componentName.text;
        }

        updatedAddressData.city = localityText || subLocalityText;

        res.address.addressComponents.forEach((component) => {
          const {
            componentName: { text },
            componentType,
          } = component;

          if (
            !(
              res.isPlaceApi &&
              !fixedComponents.includes(componentType) &&
              !res.address.formattedAddress.includes(text)
            )
          ) {
            switch (componentType) {
              case 'subpremise':
              case 'street_number':
                updatedAddressData.address_2 = updatedAddressData.address_2
                  ? updatedAddressData.address_2 + ' ' + text
                  : text;
                break;
              case 'street_address':
              case 'route':
              case 'intersection':
              case componentType.includes('sublocality') && localityText
                ? componentType
                : 1:
              case 'premise':
              case 'plus_code':
              case 'natural_feature':
              case 'airport':
              case 'park':
              case 'point_of_interest':
                updatedAddressData.address_1 = updatedAddressData.address_1
                  ? updatedAddressData.address_1 + ' ' + text
                  : text;
                break;
              case 'locality':
                updatedAddressData.city = text;
                break;
              case 'postal_town':
                updatedAddressData.city = updatedAddressData.city ?? text;
                break;
              case 'administrative_area_level_1':
                updatedAddressData.state = res.isPlaceApi
                  ? text
                  : getStateFullName(text, res.address);
                break;
              case 'postal_code':
                updatedAddressData.postalCode = text;
                break;
              case 'postal_code_suffix':
                updatedAddressData.postalCode = updatedAddressData.postalCode
                  ? updatedAddressData.postalCode + '-' + text
                  : text;
                break;
              case 'country':
                updatedAddressData.country = res.isPlaceApi
                  ? text
                  : getCountryFullName(text, res.address);
                break;
            }
          }
        });
        if (isObjectValidated(updatedAddressData)) {
          updatedResponse.push(updatedAddressData);
        }
      });
    }
    return updatedResponse;
  };

  const validateAddress = (data, isEdit, isFromRecommendationClick) => {
    try {
      // Get country ISO code i.e IN(India)
      let postData = { ...data };
      const storeData = store.getState();
      let countryStateList = storeData?.locationTab?.countryStateList;

      let country = countryStateList.find(
        (obj) => obj.country === postData.country,
      );
      postData.countryIsoCode = country?.code || '';

      setIsEdit(isEdit);
      setFromRecommendationClick(isFromRecommendationClick);

      let fullAddress = `${
        postData.address_1 ? postData.address_1 + ', ' : ''
      }${postData.address_2 ? postData.address_2 + ', ' : ''}${
        postData.city
      }, ${postData.state} ${postData.postalCode}, ${postData.country}`;
      setSubmittedAddress(fullAddress);

      setLoading(true);
      httpService
        .post(Endpoints.addressValidatorApi, postData)
        .then((res) => {
          setLocationLoading(false);
          if (res?.data) {
            let modifiedAddress = updateResponseToLocation(res.data.results);
            if (res.data?.results?.length > 0 && modifiedAddress.length > 0) {
              if (res.data?.results[0].isPlaceApi) {
                //find place api status
                if (modifiedAddress?.length > 1)
                  data.addressValidationStatus = partial;
                else
                  data.addressValidationStatus = findPlaceValidationStatus(
                    modifiedAddress[0],
                    fullAddress,
                  );
              } else {
                //find address-validator api status
                let addressValidationStatus = findValidationStatus(
                  res,
                  fullAddress,
                );
                data.addressValidationStatus = addressValidationStatus;
              }
            } else {
              data.addressValidationStatus = inValid;
            }

            setLocationValidationResponse(modifiedAddress);
            setNewPostData(data);

            switch (data.addressValidationStatus) {
              case valid:
                // Save Location
                actionClick(data, isEdit ? 'update' : 'save');
                break;
              case inValid:
                setLoading(false);
                actionClick(data, isEdit ? 'update' : 'save');
                break;
              case partial:
                setLoading(false);
                let countryStateList = storeData?.locationTab?.countryStateList;

                // Filter google recommended addresses to include only those matching our countries and states
                modifiedAddress = modifiedAddress.filter((address) => {
                  const countryMatch = countryStateList.find(
                    (countryItem) => countryItem.country === address.country,
                  );

                  if (countryMatch) {
                    return countryMatch.states.some(
                      (stateItem) => stateItem.state === address.state,
                    );
                  }

                  return false;
                });

                if (modifiedAddress.length > 0) {
                  setLocationValidationResponse(modifiedAddress);
                  setAddressVerifyModal(true);
                } else {
                  actionClick(data, isEdit ? 'update' : 'save');
                }
                break;
              default:
            }
          }
        })
        .catch((err) => {
          setLocationLoading(false);
          setLoading(false);
          locationUpdateFailed(err);
        });
    } catch (err) {}
  };

  const verifyValidLocationFields = (data, res) => {
    // double check/replace fields that pass validity check even with typos
    let newData = { ...data };
    let results = res.data.results[0];

    results.address.addressComponents.forEach((component) => {
      const {
        componentName: { text },
        componentType,
      } = component;

      switch (componentType) {
        case 'subpremise':
        case 'street_number':
          if (newData.address_2 != text)
            newData.address_2 = newData.address_2
              ? `${newData.address_2} ${text}`
              : text;
          break;
        case 'route':
          if (newData.address_1 != text) newData.address_1 = text;
          break;
        case 'locality':
          if (newData.city != text) newData.city = text;
          break;
        case 'administrative_area_level_1':
          if (newData.state != text)
            newData.state = getStateFullName(text, results.address);
          break;
        case 'postal_code':
          if (newData.postalCode != text) newData.postalCode = text;
          break;
        case 'postal_code_suffix':
          if (newData.postalCode.includes('-'))
            newData.postalCode = `${newData.postalCode}-${text}`;
          break;
        case 'country':
          if (newData.country != text)
            newData.country = text === 'USA' ? 'United States' : text;
          break;
      }
    });

    return newData;
  };

  const actionClick = (locationDetails, action) => {
    if (fromRecommendationClick) {
      setLocationLoading(true);
    } else {
      setLoading(true);
    }
    let postData = {};
    for (let key in locationDetails) {
      if (typeof locationDetails[key] === 'string') {
        postData[key] = locationDetails[key].trim();
      } else {
        postData[key] = locationDetails[key];
      }
    }

    if (action === 'update') {
      let locationPutUri = String(
        Endpoints.locationApi + '/' + postData.officeLocationId,
      );
      httpService
        .update(locationPutUri, postData)
        .then((res) => {
          setLoading(false);
          setLocationLoading(false);
          if (res?.data?.officeLocationId) {
            afterSaveLocation('Location updated');
          }
        })
        .catch((err) => {
          setLoading(false);
          setLocationLoading(false);
          locationUpdateFailed(err);
        });
    } else {
      httpService
        .post(Endpoints.locationApi, postData)
        .then((res) => {
          setLoading(false);
          setLocationLoading(false);
          if (res?.data === 0) {
            afterSaveLocation('Location saved');
          }
        })
        .catch((err) => {
          setLoading(false);
          setLocationLoading(false);
          locationUpdateFailed(err);
        });
    }
  };

  const locationUpdateFailed = (err) => {
    let errorMsg =
      err?.response?.data?.resultCode === '-2'
        ? err?.response?.data?.cause
        : GenericError.somethingWentWrong;
    Notify({
      alert: true,
      type: 'error',
      title: errorMsg,
    });
  };

  const onSelectionChanged = (elements) => {
    let rowLength = elements?.api?.getSelectedRows().length;
    setButtonIcons({
      ...buttonIcons,
      DeleteIcon: rowLength > 0,
    });
  };

  const onSelectionChangedDebounce = debounce(onSelectionChanged, 100);
  // Delete Function starts here
  const deleteLocations = () => {
    let deleteLocationsIds = [];
    let deleteLocationContracts = [];
    let keptLocations = [];
    let selectedLocations = gridRef?.current?.api?.getSelectedRows();
    if (selectedLocations && selectedLocations.length > 0) {
      selectedLocations.forEach((location) => {
        deleteLocationsIds.push(location.officeLocationId);
        deleteLocationContracts.push(location.contractNumber);
      });
      let uniqueDeleteLocationContracts = [...new Set(deleteLocationContracts)];

      let queryParams = {
        PageSize: 5000,
        id: new Date().getTime(),
        Filter: `contractNumber IN(${uniqueDeleteLocationContracts
          .map((c) => '"' + c + '"')
          .join(',')}) AND officelocationid IN(${deleteLocationsIds
          .map((id) => '"' + id + '"')
          .join(',')})`,
      };

      httpService
        .get(Endpoints.userApi, queryParams)
        .then(({ data }) => {
          let rowData = data?.results;
          // filter out locations that have users
          rowData.forEach((row) => {
            if (!keptLocations.includes(row.officeLocation)) {
              keptLocations.push(row.officeLocation);
              deleteLocationsIds = deleteLocationsIds.filter(function (e) {
                return e !== row.officeLocationId;
              });
            }
          });

          if (deleteLocationsIds.length > 0) {
            httpService
              .delete(Endpoints.locationApi, deleteLocationsIds)
              .then(({ data }) => {
                if (data) {
                  afterDeleteLocation(keptLocations, deleteLocationsIds);
                  gridRef.current.api.getSelectedNodes().forEach((node) => {
                    if (!keptLocations.includes(node.data.officeLocationName)) {
                      node.setSelected(false);
                    }
                  });
                  gridRef.current.api.onFilterChanged();
                }
              })
              .catch((err) => {
                Notify({
                  alert: true,
                  type: 'error',
                  title: GenericError.somethingWentWrong,
                });
              });
          } else {
            afterDeleteLocation(keptLocations, deleteLocationsIds);
          }
        })
        .catch(() => {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        })
        .finally(() => setLocationLoading(false));

      setLocationLoading(true);
    }
  };
  // Delete Function ends here

  const getRowId = (params) => {
    if (
      params?.data &&
      params.data.officeLocationId &&
      params.data.contractNumber
    ) {
      return (
        'location-' + params.data.officeLocationId + params.data.contractNumber
      );
    }
  };
  const afterSaveLocation = (successTitle) => {
    closeAddLocationModal();
    Notify({
      alert: true,
      type: 'success',
      title: successTitle,
    });
    gridRef.current.api.onFilterChanged();
  };
  // Delete location popup
  const afterDeleteLocation = (keptLocations, deleteLocationsIds) => {
    let deleteTitle = '';
    let type = '';

    if (deleteLocationsIds.length > 0 && keptLocations.length === 0) {
      type = 'success';
      deleteTitle =
        deleteLocationsIds.length > 1
          ? 'Locations Deleted'
          : 'Location Deleted';
    }

    if (keptLocations.length > 0) {
      type = 'error';
      deleteTitle =
        keptLocations.length > 1
          ? 'Some locations cannot be deleted as they have users associated with them. Please contact CI Support for further assistance.'
          : 'A location could not be deleted as it has users associated with it. Please contact CI Support for further assistance.';
    }

    Notify({
      alert: true,
      type: type,
      title: deleteTitle,
    });
    setButtonIcons({
      ...buttonIcons,
      DeleteIcon:
        gridRef?.current?.api?.getSelectedRows().length -
          deleteLocationsIds?.length >
        0,
    });
    if (
      gridRef?.current?.api?.getSelectedRows().length -
        deleteLocationsIds?.length ==
      0
    ) {
      gridRef.current.api.deselectAll();
    }
    gridRef.current.api.onFilterChanged();
  };

  return (
    <Container fluid>
      <div className="row mt-2">
        <div className="col">
          <input
            type="text"
            id="location-search-filter-input"
            className="form-control search-box"
            onInput={onFilterTextBoxChangedDebounce}
            placeholder="Search locations"
          />
        </div>
        {showModal && (
          <LocationAddEdit
            show={showModal}
            handleClose={closeAddLocationModal}
            contracts={contracts}
            locationDetails={editedLocation}
            setEditedLocation={setEditedLocation}
            onSaveClick={validateAddress}
            loading={loading}
          ></LocationAddEdit>
        )}

        {duplicateAddressModal.show && (
          <DuplicateAddressPopup
            loading={loading}
            handleClose={() => setDuplicateAddressModal({ show: false })}
            handleSubmit={() => {
              setDuplicateAddressModal({ show: false });
              validateAddress(
                duplicateAddressModal.data,
                duplicateAddressModal.isEdit,
                duplicateAddressModal.isFromRecommendationClick,
              );
            }}
            submittedAddress={submittedAddress}
            duplicateLocations={duplicateLocations}
          />
        )}

        {addressVerifyModal && (
          <AddressVerificationPopup
            loading={loading}
            handleClose={closeAddressVerifyModal}
            handleSubmit={submitAddressVerifyModal}
            responseData={locationValidationResponse}
            submittedAddress={submittedAddress}
          ></AddressVerificationPopup>
        )}

        {locationLoading && (
          <Loader type="scaleLoader" cssClass={loaderStyle} />
        )}

        <div className="col action-item-bar">
          <div className="add-location-group-label">
            <div className="col">
              <span className="user-grid-icons icon-divider">
                <DeleteIcon
                  onClick={deleteClickHandler}
                  className={
                    buttonIcons.DeleteIcon ? 'icon-active' : 'cursor-default'
                  }
                  alt="Delete Location"
                  width="18"
                  title="Delete Location"
                ></DeleteIcon>
              </span>
              <span className="user-grid-icons icon-divider">
                <AddLocationIcon
                  className="icon-active"
                  alt="Add location"
                  width="18"
                  title="Add location"
                  onClick={() => openLocationModal()}
                ></AddLocationIcon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="ag-grid-table with-tabs mbx-12">
        <AgGridComponent
          config={defaultConfig}
          defaultColumnDef={defaultColdef}
          columns={locationGridColumns}
          getRowId={getRowId}
          groupAllowUnbalanced={true}
          onGridReady={onGridReadyHandler}
          onSelectionChanged={onSelectionChangedDebounce}
        />
      </div>
    </Container>
  );
};

export default Locations;
