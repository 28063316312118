import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Loader from '../../components/Loader';
import httpService from '../../services/http-service';
import { useNavigate } from 'react-router-dom';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import { Endpoints } from './../../constants';
import { UrlPath } from '../../constants';
import Locations from './../locations/locations';
import UserDetails from './user-details';
import ExportUsers from './export-users/ExportUsers';
import store from './../../redux/store';
import persistDataStore from './../../redux/persistStore';
import { useSelector, useDispatch } from 'react-redux';
import { addContract } from './../../redux/actions';
import { getCSAContractsData } from './../../helpers/component-helper-methods';

const UserLocationContainer = () => {
  const [loading, setLoading] = useState(true);
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [breadCrumbValue, setBreadCrumbValue] = useState();
  const [contractsResult, setContractsResult] = useState([]);
  const [accountsData, setAccountsData] = useState([]);
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const [activeTab, setActiveTab] = useState();
  // const [renderContractDropdown, setRenderContractDropdown] = useState();
  const usersTabName = 'users';
  const locationsTabName = 'locations';
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo;

    if (storeUserInfo && storeUserInfo.userInfo) {
      getContractsDataSet(storeUserInfo.userInfo.email);
    }
  }, [useSelector((x) => x.userInfo)]);

  // set header breadcrumb to CSA selected value
  useEffect(() => {
    let csaActionData =
      persistDataStore.getState()?.userActionData?.csaUserActionInfo;
    let breadCrumbData =
      csaActionData?.type === 'account'
        ? csaActionData?.data?.account
        : csaActionData?.type === 'hccompany' ? csaActionData?.data?.homogenizedCompany : csaActionData?.value;
    setBreadCrumbValue(breadCrumbData);
  }, []);

  const getContractsDataSet = async (userEmail) => {
    let csaActionData =
      persistDataStore.getState()?.userActionData?.csaUserActionInfo;
    userEmail = userEmail.toLowerCase();
    let queryParamsContract = {};
    let selectedUserContracts = [];
    if (localStorage.hasOwnProperty('CSASelectedUserContracts')) {
      selectedUserContracts = localStorage
        .getItem('CSASelectedUserContracts')
        .split(',');
      queryParamsContract = {
        PageSize: 5000,
        Field: 'contractNumber,contractDescription,bulkEntitlementEnable',
      };
    } else {
      queryParamsContract = {
        PageSize: 5000,
        Field: 'contractNumber,contractDescription,bulkEntitlementEnable',
        Filter: `email:"${userEmail}"`,
      };
    }
    try {
      let storeData = store.getState();
      let storeContracts = storeData.contract;
      if (
        storeContracts &&
        storeContracts.contracts &&
        storeContracts.contracts.length > 0
      ) {
        let storedContract = [...storeContracts.contracts];
        setContractsResult(storedContract);
        // setTimeout(() => {
        //   setRenderContractDropdown(new Date().getTime());
        // }, 1000);
      } else {
        if (csaActionData?.type && csaActionData?.value) {
          let csaContracts = await getCSAContractsData();
          setDropDownLoader(false);
          setContractsResult(csaContracts);
          dispatch(addContract(csaContracts));
        } else {
          const contractResponse = await httpService.get(
            Endpoints.contractApi,
            queryParamsContract,
          );
          let tempState = contractResponse.data?.results?.map((el, i) => {
            return {
              label:
                el.contractNumber +
                (el.contractDescription ? ' - ' + el.contractDescription : ''),
              id: el.contractNumber,
              checked: true,
              bulkEntitlementEnable: el.bulkEntitlementEnable,
              ...el,
            };
          });
          setContractsResult(tempState);
          dispatch(addContract(tempState));
        }
      }
    } catch (err) {
    } finally {
      setDropDownLoader(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      getAccounts();
    }, 800);
    return () => clearTimeout(reduceCallback);
  }, [contractsResult]);

  const selectedCount = contractsResult?.reduce(
    (counter, obj) => (obj.checked === true ? (counter += 1) : counter),
    0,
  );

  const getAccounts = () => {
    if (contractsResult && contractsResult.some((x) => x.checked)) {
      let queryParams = {
        pageSize: 5000,
        field: 'accountId,accountName',
        Filter: `contractNumber in(${contractsResult
          .filter((x) => x.checked)
          .map((t) => (t.id ? '"' + t.id + '"' : ''))
          .join(',')})`,
      };
      httpService
        .get(Endpoints.accountApi, queryParams)
        .then((res) => {
          setAccountsData(res.data?.results);
        })
        .catch((err) => {});
    } else {
      setAccountsData([]);
    }
  };

  const handleTabSelect = (tabName) => {
    if (window.location.pathname !== UrlPath.userLocation) {
      navigate(UrlPath.userLocation);
    }
  };

  const onContractChangeHandler = (contracts) => {
    setContractsResult(contracts);
    dispatch(addContract(contracts));
  };

  const onTabSelect = (key) => {
    if (!isLocationEnabled) setIsLocationEnabled(true);
    setActiveTab(key);
  };

  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <span id="user-location-header" className="page-header">
                <span className=" breadcrumb-nav-wrapper">
                  <span className="cursor-pointer" onClick={handleTabSelect}>
                    Manage Users
                  </span>
                  {window.location.pathname === UrlPath.exportUsers ? (
                    <>
                      <span className="breadcrumb-nav">/ Export</span>
                      <span className="breadcrumb-nav" title={breadCrumbValue}>
                        {breadCrumbValue ? ` / ${breadCrumbValue}` : ''}
                      </span>
                    </>
                  ) : (
                    <>
                      &nbsp;<span>& Locations</span>
                      <span className="breadcrumb-nav" title={breadCrumbValue}>
                        {breadCrumbValue ? ` / ${breadCrumbValue}` : ''}
                      </span>
                    </>
                  )}
                </span>
              </span>
            </div>
            <div className="col-lg-4 col-md-5">
              <MultiSelectDropdown
                // searchKeys={["label"]}
                // enableSearch={true}
                enableSelectAll={true}
                defaultSelectAll={true}
                labelKeyName="label"
                ddOptionsKey="id"
                dropDownLoader={dropDownLoader}
                ddOptions={contractsResult}
                setddOptions={onContractChangeHandler}
                ddName={'contracts'}
                ddPlaceHolder={
                  selectedCount === contractsResult.length
                    ? 'Contracts: All Contracts'
                    : `Contracts: (${selectedCount})`
                }
                // renderDropdownView={renderContractDropdown}
              />
            </div>
          </div>

          {/* Tab section */}
          <div>
            {window.location.pathname === UrlPath.exportUsers ? (
              <div className="tabs-main">
                <ExportUsers contracts={contractsResult} />
              </div>
            ) : (
              <div className="tabs-main">
                <Tabs
                  fill
                  justify
                  defaultActiveKey={usersTabName}
                  activeKey={activeTab}
                  onSelect={onTabSelect}
                >
                  <Tab eventKey={usersTabName} title="Users">
                    <UserDetails
                      contracts={contractsResult}
                      accounts={accountsData}
                    ></UserDetails>
                  </Tab>
                  <Tab eventKey={locationsTabName} title="Locations">
                    {isLocationEnabled && (
                      <Locations contracts={contractsResult}></Locations>
                    )}
                  </Tab>
                </Tabs>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UserLocationContainer;
