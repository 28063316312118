import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import DropdownInput from '../../../../sharedcomponents/DropdownInput/DropdownInput';
import TextAreaInput from '../../../../sharedcomponents/TextAreaInput/TextAreaInput';
import TextFieldInput from '../../../../sharedcomponents/TextFieldInput/TextFieldInput';
import {
  Endpoints,
  GenericError,
  UserId,
  manageUsersTabName,
  entitlementTabName,
} from './../../../../constants';
import httpService from './../../../../services/http-service';
import { Notify } from './../../../../sharedcomponents/Alert/Notify';
import './create-user-group.scss';

const CreateUserGroup = ({
  contracts,
  setLoading,
  editData,
  setEditData,
  setActiveTab,
  getUserGroup,
  handleClose,
  setIsEntitlementEnabled,
  setIsManageUsersEnabled,
}) => {
  const [isDirty, setIsDirty] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [initialValue, setInitialValue] = useState({
    groupName: '',
    contractNumber: '',
    description: '',
  });

  const validate = Yup.object({
    groupName: Yup.string()
      .trim()
      .max(100, 'Must be 100 characters or less')
      .required('Please enter group name to proceed')
      .matches(
        '^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$',
        'No special characters values are accepted',
      ),
    contractNumber: Yup.string().required(
      'Please select a contract number to proceed',
    ),
    description: Yup.string()
      .trim()
      .max(2000, 'Must be 2000 characters or less'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validate,
    onSubmit: (values) => {
      AddUserGroup(values);
    },
  });

  useEffect(() => {
    setEditMode(Boolean(editData));
    if (editData) {
      setInitialValue({
        groupName: editData.groupName ?? '',
        contractNumber: editData.contractNumber ?? '',
        description: editData.description ?? '',
      });
    }
  }, [editData]);

  useEffect(() => {
    if (Boolean(editData)) {
      if (JSON.stringify(initialValue) !== JSON.stringify(formik.values)) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  }, [formik.values]);

  const onChangeHandler = (val, name) => {
    let obj = {
      target: { name: name, value: val[name] ?? val },
    };
    formik.handleChange(obj);
  };

  const onConfirmAction = (newPostData, tabName, isCloneClick) => {
    if (!editMode) {
      if (tabName == manageUsersTabName) setIsManageUsersEnabled(true);
      setIsEntitlementEnabled(true);

      setActiveTab(tabName);
      setEditData({ ...newPostData });
      setIsDirty(false);

      if (tabName == entitlementTabName && isCloneClick) {
        setTimeout(() => {
          const cloneButton = document.querySelector(
            '.clone-entitlements-icon',
          );
          if (cloneButton) {
            const event = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            cloneButton.dispatchEvent(event);
          }
        }, 100);
      }
    }
  };

  const notifyAddEditUser = (postData, response) => {
    let notify_msg = '';
    // Custom msg on Add/Edit & API response
    switch (String(response?.code)) {
      // 0 - Data added/edited to DB
      case '0': {
        notify_msg = editMode
          ? 'User Group has been updated'
          : 'User Group created successfully';
        break;
      }
      // -2 - Duplicate user group
      case '-2': {
        notify_msg = response?.cause;
        break;
      }
      default:
        notify_msg = GenericError.somethingWentWrong;
    }

    let newPostData = {};
    if (!editMode) {
      newPostData = {
        ...postData,
        groupId: response?.results?.groupId,
      };
    }

    // Notify config to show conditional alert after adding new user
    let notifyConfig = {
      confirmBtnText: 'Add users',
      onConfirm: () => onConfirmAction(newPostData, manageUsersTabName),
      cancelBtnText: 'Close',
      onCancel: () => {
        resetCreateUserGroupData();
      },
      secondBtnText: 'Add entitlements',
      onSecondAction: () => onConfirmAction(newPostData, entitlementTabName),
      showCloseBtn: false,
      thirdBtnText: 'Clone entitlements',
      onThirdAction: () =>
        onConfirmAction(newPostData, entitlementTabName, true),
    };
    Notify({
      alert: true,
      type: 'success',
      title: notify_msg,
      dialogClassName: !editMode ? 'alert-width-45' : '',
      ...(!editMode && notifyConfig),
    });
  };

  const AddUserGroup = (userGroupDetails) => {
    try {
      setLoading(true);
      let UserGroup_Info = {
        groupId: '-1',
        userId: UserId,
        ...userGroupDetails,
      };
      if (editMode) {
        UserGroup_Info.groupId = editData?.groupId;
        UserGroup_Info.contractNumber = editData?.contractNumber;
      }
      let postData = {};
      for (let key in UserGroup_Info) {
        if (typeof UserGroup_Info[key] === 'string') {
          postData[key] = UserGroup_Info[key].trim();
        } else {
          postData[key] = UserGroup_Info[key];
        }
      }
      httpService
        .post(Endpoints.userGroupApi, postData)
        .then((resp) => {
          if (resp?.data?.code === 0) {
            getUserGroup();
            notifyAddEditUser(postData, resp.data);
            editMode && resetCreateUserGroupData();
          }
        })
        .catch((err) => {
          let errorMsg =
            err?.response?.data?.resultCode === '-2'
              ? err?.response?.data?.cause
              : GenericError.somethingWentWrong;
          Notify({
            alert: true,
            type: 'error',
            title: errorMsg,
          });
        })
        .finally(() => setLoading(false));
    } catch (err) {
      setLoading(false);
    }
  };

  const resetCreateUserGroupData = async () => {
    await setInitialValue({
      groupName: '',
      contractNumber: '',
      description: '',
    });
    handleClose();
  };

  return (
    <Container className="pt-2">
      <Form className="row mt-2" onSubmit={formik.handleSubmit}>
        <TextFieldInput
          formLabel="Name"
          placeholder="Name"
          id="groupName"
          {...formik.getFieldProps('groupName')}
          formik={formik}
          addAsterisk
        />
        <DropdownInput
          formLabel="Contract Number"
          name="contractNumber"
          dropdownOptions={contracts}
          formik={formik}
          ddPlaceHolder={
            formik.values.contractNumber !== '' && contracts?.length > 0
              ? contracts?.filter(
                  (el) => el.contractNumber === formik.values.contractNumber,
                )[0]?.label
              : 'Select'
          }
          labelKeyName="label"
          ddName={'contracts'}
          disabled={editMode}
          onChangeHandler={(e) => onChangeHandler(e, 'contractNumber')}
          addAsterisk
        />
        <TextAreaInput
          formLabel="Description"
          placeholder="Description"
          id="description"
          {...formik.getFieldProps('description')}
          formik={formik}
        />
        <Form.Group className="col-12 user-save-btn-body">
          <button
            disabled={!isDirty}
            type="Submit"
            className="ug-save-btn user-save-btn btn btn-dark float-end btn-opacity"
          >
            {editMode ? 'Update' : 'Save'}
          </button>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default CreateUserGroup;
