import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  GenericError,
  UrlPath,
  loaderStyle,
  PendingActive,
  Active,
  PendingApproval,
} from '../../constants';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import { Endpoints, UserId, maxPageSize } from './../../constants';
import httpService from './../../services/http-service';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import ProductDistributorsDropdown from './ProductDistributorsDropdown';
import ProductsDropdown from './ProductsDropdown';
import UsersDropdown from './UsersDropdown';
import Loader from './../../components/Loader';

const ProductEntitlements = ({ contracts }) => {
  const [loading, setLoading] = useState(false);
  const [productsDataSet, setProductsDataSet] = useState([]);
  const [distributorDataSet, setDistributorDataSet] = useState([]);
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [usersDataSet, setUsersDataSet] = useState([]);
  const [updatedUsers, setUpdatedUsers] = useState([]);
  const [productsDistributorData, setProductsDistributorData] = useState([]);

  const callBackProducts = (props) => setProductsDataSet(props);
  const callBackDistributors = (props) => setDistributorDataSet(props);
  const callBackUsers = (props) => setUpdatedUsers(props);

  const gridRef = useRef();
  const getColumnClass = (params) => {
    if (params?.node?.data?.isEntitlementExists == false) {
      return 'new-selected-entitlement-row grid-cell-left';
    } else {
      return 'grid-cell-left';
    }
  };
  const columns = [
    {
      headerName: 'User',
      headerTooltip: 'User',
      field: 'contactData',
      tooltipField: 'contactData',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Package',
      headerTooltip: 'Package',
      field: 'packageName',
      tooltipField: 'packageName',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Product',
      headerTooltip: 'Product',
      field: 'productName',
      tooltipField: 'productName',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Status',
      headerTooltip: 'Status',
      field: 'status',
      tooltipField: 'status',
      initialFlex: 2,
      cellClass: getColumnClass,
    },
  ];
  const onDragStoppedHandler = (params) => {
    const columnDefs = columns;
    let groupCols = params.api.columnModel.rowGroupColumns;
    columnDefs.forEach(function (colDef, index) {
      if (groupCols.find((x) => x.colId === colDef.field)) {
        colDef.hide = true;
      } else {
        colDef.hide = false;
      }
    });
    gridRef.current.api.setColumnDefs(columnDefs);
  };

  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColums: true,
    enableCharts: false,
    sideBar: false,
    onDragStopped: onDragStoppedHandler,
  };

  const defaultColdef = {
    sortable: true,
    resizable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
    enableValue: false,
    cellClass: 'grid-cell-left',
  };

  const autoGroupColumnDef = {
    // column definition for grouped column
    enableRowGroup: false,
    lockPosition: 'left',
    lockPinned: true,
    sortable: true,
    cellRenderer: 'agGroupCellRenderer',
  };

  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      setProductsDistributorData([]);
      setProductsDataSet([]);
      setUsersDataSet([]);
    }, 1000);
    return () => clearTimeout(reduceCallback);
  }, [contracts]);
  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      if (productsDataSet.length > 0 && distributorDataSet.length > 0) {
        getContractProducts();
      } else if (
        productsDataSet.length === 0 ||
        distributorDataSet.length === 0
      ) {
        setProductsDistributorData(
          productsDistributorData.filter((c) => c.isEntitlementExists),
        );
      }
    }, 1000);
    return () => clearTimeout(reduceCallback);
  }, [productsDataSet, distributorDataSet, usersDataSet]);

  useEffect(() => {
    handleUserCallback(updatedUsers);
  }, [updatedUsers]);

  const getContractProducts = async () => {
    try {
      if (contracts) {
        let productIdCollection = '';
        productsDataSet.map((el, i) => {
          productIdCollection += (i > 0 ? ',' : '') + String(el?.productId);
        });

        let distributorIdCollection = '';
        distributorDataSet.map((el, i) => {
          distributorIdCollection +=
            (i > 0 ? ',' : '') + String(el?.distributorId);
        });

        if (productIdCollection && distributorIdCollection) {
          let queryParams = {
            PageSize: 5000,
            Filter: `productId in(${productIdCollection}) and distributorId in(${distributorIdCollection}) and contractNumber in(${contracts
              .filter((x) => x.checked)
              .map((t) => (t.id ? '"' + t.id + '"' : ''))
              .join(',')})`,
          };

          const productsDistributorResponse = await httpService.get(
            Endpoints.contractProductsApi,
            queryParams,
          );

          let tempData;
          if (productsDistributorData?.length > 0) {
            tempData = productsDistributorData.filter(
              (x) => x.isEntitlementExists,
            );
          } else {
            tempData = [];
          }
          usersDataSet.forEach((x) => {
            productsDistributorResponse?.data?.results?.forEach((y) => {
              if (
                y.contractNumber === x.contractNumber &&
                !tempData.some(
                  (pd) =>
                    pd.contactId === x.contactId &&
                    pd.productComponentId === y.productComponentId &&
                    [PendingActive, Active, PendingApproval].includes(
                      pd.status,
                    ),
                )
              ) {
                y.distributorData =
                  y.distributor +
                  ' : ' +
                  y.distributorPlatform +
                  ' (' +
                  y.deliveryType +
                  ')';
                y.contactData = x.contactName;
                y.isEntitlementExists = false;
                y.status = 'Pending Active';
                y.contactId = x.contactId;
                tempData.unshift({ ...y });
              }
            });
          });
          setProductsDistributorData(tempData);
        }
      }
    } catch (err) {
    } finally {
      setDropDownLoader(false);
    }
  };

  // get all entitlement mappings for user
  const getEntitlementMappings = (userIds, props) => {
    if (contracts && contracts.some((x) => x.checked)) {
      let queryParams = {
        id: new Date().getTime(),
        pageSize: maxPageSize,
        Filter: `contractNumber in(${contracts
          .filter((x) => x.checked)
          .map((t) => (t.id ? '"' + t.id + '"' : ''))
          .join(',')})
             AND contactid in(${userIds
               ?.map((t) => (t.contactId ? '"' + t.contactId + '"' : ''))
               .join(',')}) AND distributor in ("AlphaMail")
        `,
        Sort: 'modifiedDate:desc',
      };
      setLoading(true);
      httpService
        .get(Endpoints.userentitlementsApi, queryParams)
        .then((res) => {
          const response = res?.data?.results?.map((c, index) => {
            c.id = index;
            c.isEntitlementExists = true;
            c.distributorData =
              c.distributor +
              ' : ' +
              c.distributorPlatform +
              ' (' +
              c.deliveryType +
              ')';
            c.contactData = userIds.find(
              (x) => x.contactId == c.contactId,
            ).contactName;
            return c;
          });
          if (productsDistributorData.length > 0) {
            setProductsDistributorData([
              ...productsDistributorData,
              ...response,
            ]);
          } else {
            setProductsDistributorData(response);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
          if (props) setUsersDataSet(JSON.parse(JSON.stringify(props)));
        });
    } else {
      if (props) setUsersDataSet(JSON.parse(JSON.stringify(props)));
    }
  };

  const handleUserCallback = (props) => {
    //get users diff
    let removedUsers =
      props?.length > 0
        ? usersDataSet?.filter(
            (c) => !props.some((pc) => pc.contactId == c.contactId),
          )
        : usersDataSet;
    let addedUsers =
      usersDataSet?.length > 0
        ? props.filter(
            (c) => !usersDataSet.some((pc) => pc.contactId == c.contactId),
          )
        : props;

    // user added
    // add data to initials user entitlements
    if (addedUsers?.length > 0) {
      getEntitlementMappings(addedUsers, props);
    }
    // user removed
    // remove data from initial user entitlements
    if (removedUsers?.length > 0) {
      setProductsDistributorData(
        productsDistributorData.filter(
          (x) => !removedUsers.some((k) => k.contactId === x.contactId),
        ),
      );
      if (props) setUsersDataSet(JSON.parse(JSON.stringify(props)));
    }
  };

  const onGridReadyHandler = (params) => {
    gridRef.current = params;
    params?.api?.showLoadingOverlay();
  };

  const saveClick = () => {
    try {
      let entitlementsForSave = productsDistributorData
        .filter((e) => !e.isEntitlementExists)
        .map((el) => {
          return {
            userEntitlementId: '-1',
            contactId: el.contactId,
            productComponentId: el.productComponentId,
            contractNumber: el.contractNumber,
            status: 'Pending Active',
          };
        });
      let postData = {
        userEntitlements: entitlementsForSave,
        userId: UserId,
      };
      setLoading(true);
      httpService
        .post(Endpoints.userentitlementsApi, postData)
        .then((res) => {
          if (res.data === 0) {
            Notify({
              type: 'success',
              title: 'Entitlements Added',
              onConfirm: () => afterSaveClick(),
            });
          }
        })
        .catch((err) => {
          Notify({
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        })
        .finally(() => setLoading(false));
    } catch (err) {}
  };

  const navigate = useNavigate();
  const afterSaveClick = () => {
    navigate(UrlPath.entitlements);
  };

  return (
    <>
      {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}
      <Container fluid>
        <Row>
          <Col>
            <div className="add-entitlements left-0 mt-2">
              {/* Manage Email Preferences */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <UsersDropdown
              contracts={contracts}
              callBackUsers={callBackUsers}
              isGroupFilterApply={true}
            />
          </Col>
          <Col>
            <ProductsDropdown
              dropDownLoader={dropDownLoader}
              setDropDownLoader={setDropDownLoader}
              contracts={contracts}
              callBackProducts={callBackProducts}
            />
          </Col>
          {/* As per user story 6767507 this is not required now */}
          <Col className="d-none">
            <ProductDistributorsDropdown
              Type="ProductEntitlements"
              callBackDistributors={callBackDistributors}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ag-grid-add-entitlement-table">
              <div
                className={
                  productsDistributorData && productsDistributorData.length > 0
                    ? 'd-none'
                    : 'd-block starter-text'
                }
              >
                <h4>Select any item</h4>
                <p>
                  Select Users and Products to start the Entitlement Provision
                  Process.
                </p>
              </div>
              <div
                className={
                  productsDistributorData && productsDistributorData.length > 0
                    ? 'd-block ag-grid-add-entitlement-table'
                    : 'd-none'
                }
              >
                <AgGridComponent
                  autoGroupColumnDef={autoGroupColumnDef}
                  config={defaultConfig}
                  defaultColumnDef={defaultColdef}
                  data={productsDistributorData}
                  columns={columns}
                  onGridReady={onGridReadyHandler}
                />
                <Col className="float-end mt-2 mb-2">
                  <button
                    className="btn btn-dark btn-opacity"
                    onClick={saveClick}
                    disabled={productsDistributorData?.every(
                      (x) => x.isEntitlementExists,
                    )}
                  >
                    SAVE ENTITLEMENTS
                  </button>
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductEntitlements;
