import httpService from '../../services/http-service';
import Toast from './../Toast/Toast';
import FileSaver from 'file-saver';
import { maxExportSizeLimit } from '../../constants';
import { Notify } from '../../sharedcomponents/Alert/Notify';

const ExportServerSide = async (
  gridRef,
  requestURL,
  queryParams,
  fileName,
  customValueGetter,
  getMergedColName,
) => {
  try {
    const selectedRows = gridRef.current?.api?.getSelectedRows();
    const selectedRowsLength = selectedRows?.length;
    const isGrouped = gridRef.current?.columnApi
      ?.getColumns()
      .some((col) => col.rowGroupActive);

    let toastId = null;

    const { selectAllChildren, toggledNodes } =
      gridRef.current?.api?.getServerSideSelectionState();

    const isSelectAllCheckboxChecked = () => {
      // checks for selectall boolean whether selection was made before or not
      let selectAllCheckbox = false;
      if (selectAllChildren) {
        if (toggledNodes && toggledNodes.length > 0) {
          selectAllCheckbox = false;
        } else {
          selectAllCheckbox = true;
        }
      }
      return selectAllCheckbox;
    };

    const getGroupCSVData = (params) => {
      // Export CSV on group including grouped column data
      if (!params.value) {
        let displayedColumns = params?.api?.getAllDisplayedColumns();
        let currentColumn = displayedColumns[params?.accumulatedRowIndex];
        let columnId = currentColumn?.colId?.split('ag-Grid-AutoColumn-')[1];
        return customValueGetter(params?.node, columnId);
      } else if (params?.node?.group) {
        return null;
      } else {
        return params?.value;
      }
    };

    const successNotification = () => {
      Notify({
        alert: true,
        type: 'success',
        dialogClassName: 'alert-export-success-max-width',
        title:
          'Please note that the exported file contains maximum of ' +
          maxExportSizeLimit +
          ' records. Please download additional records (if any) separately',
      });
    };

    const getExcelFileForExport = async (queryParams, groupByBody) => {
      toastId = Date.now();
      // Toast({
      //   message: 'Fetching data for export',
      //   type: 'info',
      //   isLoading: true,
      //   toastId,
      // });

      Notify({
        alert: true,
        type: '',
        title: 'Your download is in progress',
      });

      await httpService
        .post(requestURL, groupByBody, queryParams, {
          responseType: 'blob',
          contentType: 'text/plain',
        })
        .then(async ({ data }) => {
          FileSaver.saveAs(data, fileName + '.xlsx');
          successNotification();
        })
        .catch((err) => {});
    };

    const ExportRecords = (onlySelected = false) => {
      // Export the records after fetching all records
      setTimeout(() => {
        gridRef.current?.api?.exportDataAsCsv({
          onlySelected,
          fileName: fileName,
          processCellCallback: getGroupCSVData,
        });

        successNotification();

        // Dismiss the fetching data notification
        // Toast.dismiss(toastId);

        // Success notification
        // Toast({ message: 'Export Successful', type: 'success' });
      }, 200);
    };

    const getFirstGroupedColumnField = () => {
      // Get all column definitions from the grid API
      const columnDefs = gridRef.current?.columnApi.getAllGridColumns();

      // Find the first column definition that has rowGroup set to true
      const firstGroupedColumn = columnDefs.find((col) => col?.rowGroupActive);

      // Return the field name of the first grouped column or null if no grouped columns are found
      return firstGroupedColumn ? firstGroupedColumn.colId : null;
    };

    const createGrpBody = () => {
      let grpBody = '';
      let firstGrpColName = getFirstGroupedColumnField();
      let mergedColName = getMergedColName(firstGrpColName);
      gridRef.current?.api.getSelectedNodes().forEach((el) => {
        if (el.group && el.uiLevel === 0) {
          let value =
            el.data[firstGrpColName] ?? customValueGetter(el, firstGrpColName);
          if (value) {
            if (grpBody.length === 0) {
              grpBody = `${mergedColName ?? firstGrpColName} IN (`;
            }
            grpBody += `"${value}",`;
          }
        }
      });
      grpBody += `)`;
      grpBody = grpBody.replace(',)', ')');

      return grpBody;
    };

    const applySortForGroupCol = () => {
      let querySort = '';
      let firstGrpColName = getFirstGroupedColumnField();
      if (!queryParams.Sort.includes(firstGrpColName)) {
        querySort = `${firstGrpColName}:asc`;
      }
      return querySort;
    };

    const anyNonGroupRowSelected = async () => {
      const selectedNodes = gridRef.current?.api.getSelectedNodes();

      // Check if any selected node is a non-group row
      const isNonGroup = selectedNodes.some(
        (node) => !node?.group && node?.parent?.selected !== true,
      );

      return isNonGroup;
    };

    // ----- Start export -----
    // Select all checkbox's state
    const selectAllChecked = isSelectAllCheckboxChecked();

    let pageNumber = 1;
    queryParams.Page = pageNumber;
    queryParams.PageSize = maxExportSizeLimit;

    let groupByBody = '';

    if (isGrouped) {
      queryParams.Sort = applySortForGroupCol();
      if (!selectAllChecked) groupByBody = createGrpBody();
    }

    let isAnyNonGroupRowSelected = await anyNonGroupRowSelected();

    if (selectAllChecked || (isGrouped && !isAnyNonGroupRowSelected)) {
      // Export all records, whether grouped or not
      await getExcelFileForExport(queryParams, groupByBody);
    } else {
      // Export only selected records
      ExportRecords({ onlySelected: true });
    }
    // Toast({ message: 'Export finished', type: 'success' });
  } catch (error) {}
};

export default ExportServerSide;
