import './assets/scss/light.scss';
import 'react-app-polyfill/stable';
import store from '../src/redux/store';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './services/mock-server';
import { LicenseManager } from 'ag-grid-enterprise';
import { Provider } from 'react-redux';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-046310 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( S&P Global Inc. )_is_granted_a_( Multiple Applications )_Developer_License_for_( 10 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 October 2024 )____[v2]_MTcyODI1NTYwMDAwMA==14f588479e7ab26443961ae3faa0928f',
  // 'CompanyName=SHI International Corp_on_behalf_of_S&P GLOBAL,LicensedGroup=S&P Global Platts,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-008228,ExpiryDate=07_October_2024_[v2]_MTcyODI1NTYwMDAwMA==14f588479e7ab26443961ae3faa0928f',
);
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement,
);

registerServiceWorker();
