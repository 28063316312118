import { Container, Navbar } from 'react-bootstrap';

import logo from './../../assets/img/logo/logo.svg';

const NavbarComponent = () => {
  return (
    <Navbar
      variant="light"
      expand
      className="navbar-bg nav-shadow"
      sticky="top"
    >
      <Container fluid className="mx-3">
        <div className="logo-section">
          <a className="navbar-brand" href="/">
            <img src={logo} className="img-fluid me-1 logo-img" alt="Admin" />
          </a>
          <span className="logo-sub-heading">Subscription Manager</span>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
